import React, { useMemo, useState } from 'react';
import { MessageListContainer } from './messages-list.style';
import { SearchInput, UserIcon } from '../../ui';
import { TUserMessage } from '../../../pages/messages';

type MessageListProps = {
  users: TUserMessage[];
  onUserSelected: (userId: string) => void;
  selectedUserId?: string;
};

export const MessageList = ({
  users,
  onUserSelected,
  selectedUserId,
}: MessageListProps) => {
  const [search, setSearch] = useState('');

  const filteredUsers = useMemo(
    () =>
      users.filter((el) =>
        (el.firstName + ' ' + el.lastName)
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()),
      ),
    [search, users],
  );

  return (
    <MessageListContainer>
      <div className="top-container">
        <SearchInput
          placeholder="Search message..."
          onChange={(e) => setSearch(e.target.value ?? '')}
        />
        <div className="filter-container">
          <div className="filter-item active">All</div>
          <div className="filter-item">Unread</div>
        </div>
      </div>
      <div className="bottom-container inbox-list">
        {filteredUsers.map((el, index) => (
          <div
            className={`inbox-item ${el._id === selectedUserId && 'active'}`}
            key={index}
            onClick={() => onUserSelected(el._id)}
          >
            <div className="profile-image">
              <UserIcon firstName={el.firstName} lastName={el.lastName} />
            </div>
            <div className="inbox-container">
              <div className="inbox-header">
                <div className="inbox-name">
                  {el.firstName} {el.lastName}
                </div>
                <div className="inbox-time">
                  {/* {getTimeSince(el.lastMessageTime)} */}
                </div>
              </div>
              <div className="inbox-content">
                <div className="last-message">click to read messages</div>
                {Number(el?.unreadMessages) > 0 && (
                  <div className="number-unread-messages">
                    {el.unreadMessages}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </MessageListContainer>
  );
};
