import styled from 'styled-components';
import { ITheme, breakpoint } from '../../../constants';

interface HeaderContainerProps {
  isSidebarActive: boolean;
  theme: ITheme;
}

const calculateWidth = ({ isSidebarActive, theme }: HeaderContainerProps) =>
  `calc(100% - ${isSidebarActive ? theme.sidebar.open : theme.sidebar.close})`;

export const HeaderContainer = styled.header<HeaderContainerProps>`
  width: ${calculateWidth};
  height: ${(props) => props.theme.header.height};
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  background-color: ${(props) => props.theme.whiteColor};
  transition: 0.5s;
  z-index: 2;
  .toggle-sidebar {
    color: ${(props) => props.theme.firstColor};
    font-size: 1.5rem;
    cursor: pointer;
  }
  .left-header {
    display: flex;
    align-items: center;
    gap: 10px;
    .search-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f8f8f8;
      padding: 8px;
      gap: 10px;
      border-radius: 10px;
      path {
        fill: black;
      }
      input {
        border: none;
        background: transparent;
        outline: none;
      }
    }
    .links-container {
      display: flex;
      gap: 40px;
      margin: 0 40px;
      a {
        color: ${(props) => props.theme.text.blackColor};
        &.disabled {
          color: gray;
        }
        &:not(.disabled):hover {
          text-decoration: underline;
        }
      }
    }
  }
  .right-header {
    display: flex;
    gap: 24px;
    align-items: center;
    .notification-modal-button {
      display: none;
    }
    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 5px;
      &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
          rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
      }
    }

    .dropdown-menu {
      .dropdown-item {
        width: 100%;
        cursor: pointer;
      }
    }

    .dropdown-menu.show {
      display: flex;
      &:not(.notifications-container).show {
        transform: translate3d(-146px, 34px, 0px) !important;
      }

      &.notifications-container {
        flex-direction: column;

        &.show {
          transform: translate3d(-235px, 34px, 0px) !important;
        }
      }
    }

    .notifications-container {
      width: 400px;
      padding: 16px;
      flex-direction: column;
      gap: 16px;
      .notification-header {
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
      }
      .notification-content-list {
        .notification-item {
          padding: 20px 16px;
          border-radius: 10px;
          cursor: pointer;
          .notification-item-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .notification-title {
              font-size: 12px;
              font-weight: 600;
              color: #111827;
            }
            .notification-time {
              font-size: 10px;
              font-weight: 400;
              color: #a0aec0;
            }
          }
          .notification-item-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            .notification-description {
              font-weight: 400;
              color: #687588;
              font-size: 10px;
            }
          }

          &:hover {
            background-color: #8080801a;
          }
        }
      }
      .notification-action {
        > div {
          justify-content: center;
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    width: 100%;
    height: ${(props) => props.theme.header.mobileHeight};
    padding: 0 20px;

    .right-header {
      gap: 24px;
      .dropdown-notification {
        display: none;
      }
      .notification-modal-button {
        display: block;
      }
    }
  }
`;
