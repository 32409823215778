import React, { useEffect, useState } from 'react';
import { TitleCard, FieldFile, RadioGroup, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { uploadCandidateFile } from '../../../utils';
import { Compliance, HostedFile } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SelectFileList } from '../../candidate/select-file/select-file-list.component';
import { SideModal } from '../../modals';
import { DocumentsListComponent } from '../../document/documents-list.component';

type DbsCheckProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: any,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'dbsCertificateFile';

export const DbsCheck = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: DbsCheckProps) => {
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState<TFilesKey | false>(false);
  const [isValid, setIsValid] = useState<boolean>();

  const [filesList, setFilesList] = useState<{ key: TFilesKey; file: any }[]>([
    { key: 'dbsCertificateFile', file: {} },
  ]);
  const { register, getValues, setValue } = useForm<{
    dbsCertificateFile: any;
    onDbsUpdateService: string;
  }>();

  const onClickDelete = (key: TFilesKey) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].file = null;
      onUpdateCompliance(
        key,
        prev[itemIndex].file,
        'File deleted successfully',
      );
      return [...prev];
    });
  };

  const handleFileChange = async (event: any, key: TFilesKey) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      await uploadCandidateFile(id!, selectedFile)
        .then((response) => {
          event.target.value = '';
          addNewFile(key, response);
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    } else {
      console.log('No file selected');
    }
  };

  const addNewFile = (key: TFilesKey, file: any) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].file = file;
      onUpdateCompliance(key, prev[itemIndex].file);
      return [...prev];
    });
  };

  const onUpdateCompliance = (
    key: 'dbsCertificateFile' | 'onDbsUpdateService',
    value: any,
    successMessage?: string,
  ) => {
    setValue(key, value);
    onClickSubmit(
      compliance._id,
      {
        dbsCertificateFile: getValues('dbsCertificateFile'),
        onDbsUpdateService: getValues('onDbsUpdateService')
          ? getValues('onDbsUpdateService') === 'true'
            ? true
            : false
          : undefined,
      },
      false,
      successMessage,
    );
  };

  const getFiles = (key: TFilesKey) => {
    const file = filesList.find((el) => el.key === key)?.file;

    if (file?.fileName) {
      return [file];
    }

    return [];
  };

  useEffect(() => {
    if (compliance?._id) {
      setValue(
        'onDbsUpdateService',
        compliance.metadata?.onDbsUpdateService?.toString() ?? 'false',
      );
      const dbsCertificateFile =
        (compliance?.metadata as any)?.dbsCertificateFile ?? {};
      setValue('dbsCertificateFile', dbsCertificateFile);
      setFilesList([{ key: 'dbsCertificateFile', file: dbsCertificateFile }]);
      setIsValid(compliance.isValid);
    }
  }, [compliance?._id]);

  return (
    <>
      <TitleCard>DBS Certificate</TitleCard>
      <>
        <div className="row upload-buttons-container">
          <div className="col-md-6">
            <FieldFile
              label="Upload enhanced DBS certificate "
              onChange={(e) => handleFileChange(e, 'dbsCertificateFile')}
            />
          </div>
          <div
            className="col-md-6 button-select"
            onClick={() => setIsModalOpen('dbsCertificateFile')}
          >
            <Button type="primary">Select from files</Button>
          </div>
        </div>
        <DocumentsListComponent
          documents={getFiles('dbsCertificateFile')}
          onClickDelete={() => onClickDelete('dbsCertificateFile')}
          getDocuments={getDocuments}
          onDocumentRename={() => reFetchCompliance?.()}
        />
      </>
      <TitleCard>Is it on the DBS update service </TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ]}
        name="onDbsUpdateService"
        register={register('onDbsUpdateService', { required: true })}
        onChange={(value) => onUpdateCompliance('onDbsUpdateService', value)}
      />
      <TitleCard>Approved</TitleCard>
      <RadioGroup
        options={[
          {
            label: 'Pass',
            value: 'true',
          },
          {
            label: 'Fail',
            value: 'false',
          },
        ]}
        name="approved"
        value={isValid?.toString()}
        onChange={(value: any) => onUpdateValidation(compliance._id, value)}
      />
      <div className="info-card compliance-status buttons-container validated-by-ai">
        <Button
          type="primary"
          onClick={() => onClickSubmit(compliance._id, getValues(), true)}
        >
          Validate By AI
        </Button>
      </div>
      <SideModal
        isOpen={!!isModalOpen}
        setIsOpen={setIsModalOpen as any}
        title="Select File"
      >
        <SelectFileList
          fileKey={isModalOpen as any}
          setIsOpen={setIsModalOpen as any}
          documents={documents}
          addNewFile={addNewFile}
        />
      </SideModal>
    </>
  );
};
