import React, { useState } from 'react';
import { Badge, Switch, Table } from '../../ui';
import { DeleteIcon, EditIcon, EyeIcon } from '../../../icons';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  formatDate,
} from '../../../utils';
import { Compliance } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { TUIType } from '../../../constants';
import { SideModal } from '../../modals';
import { ComplianceDateForm } from '../compliance-date-form/compliance-date-form.component';
import { isCRMApp } from '../../../environment/app.type';
import { useNavigate, useParams } from 'react-router-dom';
import { ComplianceStatus } from '../compliance-status/compliance-status.component';
enum EComplianceStatus {
  NOT_ACTIVE = 'NOT_ACTIVE',
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
}

const ComplianceStatusBadge = ({ status }: { status: EComplianceStatus }) => {
  let value: { text: string; type: TUIType } = {
    text: EComplianceStatus.NOT_ACTIVE,
    type: 'default',
  };

  switch (status) {
    case EComplianceStatus.PENDING:
      value = { text: 'Pending', type: 'info' };
      break;
    case EComplianceStatus.ACTIVE:
      value = { text: 'ACTIVE', type: 'success' };
      break;
    case EComplianceStatus.REJECTED:
      value = { text: 'Rejected', type: 'default' };
      break;
    case EComplianceStatus.EXPIRED:
      value = { text: 'Expired', type: 'danger' };
      break;
    default:
      value = { text: 'Not Active', type: 'warning' };
      break;
  }

  return <Badge text={value.text} type={value.type} />;
};

type ComplianceListProps = {
  compliancesList: Compliance[];
  getComplianceList?: () => Promise<void>;
};

export const ComplianceList = ({
  compliancesList,
  getComplianceList,
}: ComplianceListProps) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [isOpen, setIsOpen] = useState<Compliance | null>(null);

  const onUpdateRequired = async (
    compliance: Compliance,
    newValue: boolean,
  ) => {
    if (!compliance.reminderDate || !compliance.expiryDate) {
      toast.warning('Please set the reminder date and the expiry date');
      return;
    }

    await AxiosInstance.compliances
      .compliancesControllerUpdate(
        compliance._id,
        { validate: false },
        {
          isRequired: newValue,
        },
      )
      .then(() => {
        getComplianceList?.();
        toast.success('Compliance updated successfully');
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th>Task</th>
            <th>Reminder Date</th>
            <th>Expiry Date</th>
            <th>Status</th>
            <th>Required</th>
            <th className="action-th">Action</th>
          </tr>
        </thead>
        <tbody>
          {compliancesList.map((el, index) => (
            <tr key={el._id}>
              <td>
                <div
                  className={`task-container ${!el.isRequired && 'disabled'}`}
                >
                  <input
                    type="checkbox"
                    id={`task-checkbox-${el._id}`}
                    checked={el.isRequired && el.isValid}
                    disabled
                  />
                  <label htmlFor={`task-checkbox-${el._id}`}>
                    {index + 1}. {el.taskName}
                  </label>
                </div>
              </td>
              <td>{el.reminderDate ? formatDate(el.reminderDate) : '-'}</td>
              <td>{el.expiryDate ? formatDate(el.expiryDate) : '-'}</td>
              <td>
                {!el.isRequired ? (
                  <ComplianceStatusBadge
                    status={EComplianceStatus.NOT_ACTIVE}
                  />
                ) : (
                  <ComplianceStatus value={el.status} />
                )}{' '}
              </td>
              <td>
                <Switch
                  value={el.isRequired}
                  onClick={() => {
                    !isCRMApp && onUpdateRequired(el, !el.isRequired);
                  }}
                />
              </td>
              <td>
                <div className="action-item">
                  {!isCRMApp && (
                    <>
                      <div className="edit-icon" onClick={() => setIsOpen(el)}>
                        <EditIcon />
                      </div>
                      <div
                        className="view-icon"
                        onClick={() =>
                          navigate(
                            `/${ERoute.CANDIDATES}/${id}/compliance/${el.taskName}`,
                          )
                        }
                      >
                        <EyeIcon />
                      </div>
                    </>
                  )}
                  <div className="delete-icon disabled">
                    <DeleteIcon />
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <SideModal
        isOpen={!!isOpen}
        setIsOpen={setIsOpen as any}
        title={'Update Date'}
      >
        <ComplianceDateForm
          compliance={isOpen!}
          onCancel={() => setIsOpen(null)}
          onSuccess={() => {
            getComplianceList?.();
            setIsOpen(null);
          }}
        />
      </SideModal>
    </>
  );
};
