import * as yup from 'yup';

const PayrollSchema = yup.object().shape({
  payrollProviderName: yup
    .string()
    .required('Payroll provider name is required.'),
  vatNumber: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Vat must be valid number')
    .required('Vat number is required'),
  payrollLogo: yup.object(),
  title: yup.string().required('Title is required.'),
  leadContactFirstName: yup
    .string()
    .required('Lead contact first name is required.')
    .matches(
      /^[a-zA-Z\s-']*$/,
      'First Name must contain only alphanumeric characters, spaces , apostrophes and hyphens',
    ),
  leadContactLastName: yup
    .string()
    .required('Lead contact last name is required.')
    .matches(
      /^[a-zA-Z\s-']*$/,
      'Last Name must contain only alphanumeric characters, spaces , apostrophes and hyphens',
    ),
  leadContactEmail: yup
    .string()
    .email('Invalid email format.')
    .required('Lead contact email is required.'),
  leadContactJobTitle: yup
    .string()
    .required('Lead contact job title is required.')
    .matches(
      /^[a-zA-Z]*$/,
      'Job title must contain only alphabetic characters',
    ),
  leadContactMobilePhoneNumber: yup
    .string()
    .matches(
      /^\+\d+$/,
      'Phone number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars')
    .required('mobile phone number is required.'),
  leadContactWorkPhoneNumber: yup
    .string()
    .matches(
      /^\+\d+$/,
      'work number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars')
    .required('Lead contact work phone number is required.'),
  leadContactFax: yup
    .string()
    .matches(/^\+\d+$/, 'fax should start with + sign and contain only digits')
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars')
    .required('Lead contact fax is required.'),
  website: yup
    .string()
    .required('Website is required.')
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9]+\.)*[a-zA-Z0-9][a-zA-Z0-9-]+(\.[a-zA-Z]{2,})?(:\d{1,5})?(\/\S*)?$/,
      'Invalid website URL. Please enter a valid URL',
    ),
  account: yup.string(),
  employmentType: yup.string(),
  standardWorkingHoursPerDay: yup.string(),
  document: yup.object(),
  address: yup.object().shape({
    street: yup.string().required('Street is required'),
    city: yup
      .string()
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'City is not valid')
      .required('City is required'),
    county: yup
      .string()
      .min(1, 'County is required')
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'County is not valid')
      .required('County is required'),
    region: yup
      .string()
      .min(1, 'Region is required')
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Region is not valid')
      .required('Region is required'),
    country: yup
      .string()
      .min(1, 'Country is required')
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Country is not valid')
      .required('Country is required.'),
    zip: yup
      .string()
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'ZIP code is not valid')
      .required('ZIP code is required'),
  }),
  bank: yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Bank name is not valid')
      .required('Bank name is required'),
    accountName: yup
      .string()
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Account name is not valid')
      .required('Account name is required'),
    accountNumber: yup.string().required('Account number is required'),
    sortNumber: yup.string().required('Sort code is required'),
  }),
  recruiterId: yup.string().required('Recruiter  is required'),
  candidate: yup.string(),
  job: yup.object(),
});

export default PayrollSchema;
