import styled from 'styled-components';

export const MessagesPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  .messages-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .messages-container {
    display: flex;
    flex-direction: row;
    padding: 0;
    max-height: 82vh;
    height: 82vh;
  }
  .filter-container {
    display: flex;
    gap: 20px;
    padding: 0px 24px;
    .filter-item {
      padding: 10px 20px;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      padding: 10px 5px;
      cursor: pointer;
      &:hover {
        color: #27a376;
        opacity: 0.9;
      }
      &.active {
        color: #27a376;
        border-bottom: 2px solid #27a376;
      }
    }
  }
`;
