import * as yup from 'yup';

export const PlacementValidateSchema = yup.object().shape({
  fee: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('Fee must be a valid number.')
    .required('Fee is required.')
    .positive('On Call Rate Amount must be a positive number'),

  vat: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('VAT must be a valid number.')
    .required('VAT is required.')
    .positive('On Call Rate Amount must be a positive number'),

  onCallFee: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('On Call Fee must be a valid number.')
    .required('On Call Fee is required.')
    .positive('On Call Rate Amount must be a positive number')
    .test(
      'is-greater-than',
      'On Call Fee must be greater than On Call Rate',
      function (value) {
        const onCallRate = this.parent.onCallRate;
        return value > onCallRate;
      },
    ),

  onCallRate: yup
    .number()
    .transform((value, originalValue) =>
      !originalValue ? null : Number(value),
    )
    .typeError('On Call Rate must be a valid number.')
    .required('On Call Rate is required.')
    .positive('On Call Rate must be a positive number'),
});
