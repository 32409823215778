import * as yup from 'yup';

const clientSchema = yup.object().shape({
  clientName: yup
    .string()
    .required('Client name is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'Client name must contain only alphanumeric characters, spaces and hyphens',
    ),

  clientSiteName: yup
    .string()
    .required('Client site name is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'Client site name must contain only alphanumeric characters, spaces and hyphens',
    ),
  clientSite: yup
    .string()
    .required('Client site is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'Client site must contain only alphanumeric characters, spaces and hyphens',
    ),
  title: yup.string().required('Title is required'),
  firstName: yup
    .string()
    .required('First name is required')
    .matches(
      /^[a-zA-Z\s-']*$/,
      'First Name must contain only alphanumeric characters, spaces , apostrophes and hyphens',
    ),
  lastName: yup
    .string()
    .required('Last name is required')
    .matches(
      /^[a-zA-Z\s-']*$/,
      'Last Name must contain only alphanumeric characters, spaces , apostrophes and hyphens',
    ),
  jobTitle: yup
    .string()
    .required('Job title is required')
    .matches(
      /^[a-zA-Z0-9\s-]*$/,
      'Job title must contain only alphanumeric characters, spaces and hyphens',
    ),
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  phoneNumber: yup
    .string()
    .matches(
      /^\+\d+$/,
      'Phone number should start with + sign and contain only digits',
    )
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars')
    .required('Phone number is required.'),
  faxNumber: yup
    .string()
    .matches(/^\+\d+$/, 'Fax should start with + sign and contain only digits')
    .min(9, 'should be between 9 and 20 chars')
    .max(20, 'should be between 9 and 20 chars'),
  website: yup
    .string()
    .required('Website is required')
    .matches(
      /^(https?:\/\/)?([a-zA-Z0-9]+\.)*[a-zA-Z0-9][a-zA-Z0-9-]+(\.[a-zA-Z]{2,})?(:\d{1,5})?(\/\S*)?$/,
      'Invalid website URL. Please enter a valid URL',
    ),
  address: yup.object().shape({
    street: yup.string().required('Street is required'),
    city: yup
      .string()
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'City is not valid')
      .required('City is required'),
    county: yup
      .string()
      .min(1, 'County is required')
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'County is not valid')
      .required('County is required'),
    region: yup
      .string()
      .min(1, 'Region is required')
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Region is not valid')
      .required('Region is required'),
    country: yup
      .string()
      .min(1, 'Country is required')
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Country is not valid')
      .required('Country is required.'),
    zip: yup
      .string()
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'ZIP code is not valid')
      .required('ZIP code is required'),
  }),
  recruiterId: yup.string().required('Recruiter is required'),
  bank: yup.object().shape({
    name: yup
      .string()
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Bank name is not valid')
      .required('Bank name is required'),
    accountName: yup
      .string()
      .matches(/^[a-zA-Z0-9\s'’\-]*$/, 'Account name is not valid')
      .required('Account name is required'),
    accountNumber: yup.string().required('Account number is required'),
    sortNumber: yup.string().required('Sort code is required'),
  }),
  whatsappId: yup.string(),
});

export { clientSchema };
