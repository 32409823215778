import { io } from 'socket.io-client';

export const socket = io(process.env.REACT_APP_API_URL!);

export enum WebsocketMessagesTypes {
  SELECT = 'select', // for getting which client is being showed on UI
  MESSAGE = 'message', // for sending messages of showed candidate
  NOTIFICATION = 'notification',
  EMAIL = 'email',
}

// import { io } from 'socket.io-client';

// export const socket = io(
//   process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api',
// );

// export enum WebsocketMessagesTypes {
//   SELECT = 'select', // for getting which client is being showed on UI
//   MESSAGE = 'message', // for sending messages of showed candidate
//   NOTIFICATION = 'notification',
// }
