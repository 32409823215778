import React, { useEffect, useMemo, useState } from 'react';
import {
  DeleteIcon,
  DownloadIcon,
  DragDropIcon,
  EyeIcon,
  LeftArrowIcon,
  PlusIcon,
  UploadIcon,
} from '../../../icons';
import {
  ATag,
  Button,
  CardContainer,
  FieldText,
  Table,
  TitlePage,
} from '../../../components';
import { VacancyDetailsContainer } from './vacancy-details.style';
import { AxiosInstance, formatDate } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { Client, Job, Recruiter } from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { durations } from '../../../constants';
import { DocumentsListComponent } from '../../../components/document';
import { DragDropUpload } from '../../../components/drag-drop-upload/drag-drop-upload.component';

enum EVacancyDetailsTabs {
  GENERAL = 'General',
  CONTACT = 'Contact',
  AVAILABILITY = 'Availability',
  PAYROLL = 'Payroll',
  DOCUMENTS = 'Documents',
}

const tabs = [
  EVacancyDetailsTabs.GENERAL,
  EVacancyDetailsTabs.CONTACT,
  EVacancyDetailsTabs.DOCUMENTS,
];

export const VacancyDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(EVacancyDetailsTabs.GENERAL);
  const [vacancy, setVacancy] = useState<Job | null>(null);

  const onClickDelete = (id: string | number) => {};
  const onClickDownload = (id: string | number) => {};
  const onClickView = (id: string | number) => {};
  const onClickEdit = (id: string | number) => {};

  const getVacancyDetails = () => {
    AxiosInstance.jobs
      .jobsControllerGetJob(id!)
      .then((response) => {
        setVacancy(response as any);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getVacancyDetails();
  }, []);

  if (!vacancy) {
    return <></>;
  }

  return (
    <VacancyDetailsContainer>
      <div className="vacancy-header">
        <div className="back-button" onClick={onClickGoBack}>
          <LeftArrowIcon />
        </div>
        <TitlePage>Detail Vacancy</TitlePage>
      </div>
      <CardContainer className="vacancy-information-container">
        <div className="tab-container">
          <div className="tab-items-list">
            {tabs.map((el) => (
              <ATag
                className={`tab-item ${el === selectedTab && 'active'}`}
                onClick={() => setSelectedTab(el)}
              >
                {el}
              </ATag>
            ))}
          </div>
          <div className="tab-content-container">
            {selectedTab === EVacancyDetailsTabs.GENERAL && (
              <>
                <div className="info-card">
                  <div className="info-card-title">Vacancy Details</div>
                  <hr />
                  <div className="info-card-content row">
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Client Name</div>
                      <div className="info-item-data col-8">
                        {vacancy?.client?.clientName}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Specialty</div>
                      <div className="info-item-data col-8">
                        {vacancy.specialty}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">
                        Job Description
                      </div>
                      <div className="info-item-data col-8">
                        {vacancy.jobDescription}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Grade</div>
                      <div className="info-item-data col-8">
                        {vacancy.grade}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Start Date</div>
                      <div className="info-item-data col-8">
                        {formatDate(vacancy.vacantFrom)}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">End Date</div>
                      <div className="info-item-data col-8">
                        {formatDate(vacancy.vacantTo)}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">Pay Option</div>
                      <div className="info-item-data col-8">
                        {vacancy.payOption}
                      </div>
                    </div>
                    <div className="col-md-6 row">
                      <div className="info-item-title col-4">VAT</div>
                      <div className="info-item-data col-8">{vacancy.vat}</div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {selectedTab === EVacancyDetailsTabs.CONTACT && (
              <div className="info-card">
                <div className="info-card-title">Contact Details</div>
                <hr />
                <div className="info-card-content row">
                  <div className="col-md-6 row">
                    <div className="info-item-title col-4">Title</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.title}
                    </div>
                  </div>
                  <div className="col-md-6 row">
                    <div className="info-item-title col-4">First Name</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.firstName}
                    </div>
                  </div>
                  <div className="col-md-6 row">
                    <div className="info-item-title col-4">Last Name</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.lastName}
                    </div>
                  </div>
                  <div className="col-md-6 row">
                    <div className="info-item-title col-4">Job Title</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.jobTitle}
                    </div>
                  </div>
                  <div className="col-md-6 row">
                    <div className="info-item-title col-4">Email Address</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.email}
                    </div>
                  </div>
                  <div className="col-md-6 row">
                    <div className="info-item-title col-4">Mobile Number</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.phoneNumber}
                    </div>
                  </div>
                  <div className="col-md-6 row">
                    <div className="info-item-title col-4">
                      Work Phone Number
                    </div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.workPhoneNumber}
                    </div>
                  </div>
                  <div className="col-md-6 row">
                    <div className="info-item-title col-4">Website</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.website}
                    </div>
                  </div>
                  <div className="col-md-6 row">
                    <div className="info-item-title col-4">Fax Number</div>
                    <div className="info-item-data col-8">
                      {vacancy?.client?.faxNumber}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === EVacancyDetailsTabs.DOCUMENTS && (
              <>
                <div className="info-card">
                  <div className="info-card-title">Personal Documents</div>
                  <hr />
                  <DocumentsListComponent
                    documents={[]}
                    onClickDelete={() => {}}
                    getDocuments={() => {}}
                  />
                </div>
                <div className="info-card">
                  <DragDropUpload onUpload={(file: File) => ({} as any)} />
                </div>
              </>
            )}
          </div>
        </div>
      </CardContainer>
    </VacancyDetailsContainer>
  );
};
