import React, { useEffect, useRef, useState } from 'react';
import { MessageContentContainer } from './messages-content.style';
import {
  AttachFileIcon,
  DotsIcon,
  DownloadIcon,
  RightArrowIcon,
} from '../../../icons';
import { ERoute } from '../../../utils';
import { Textarea, UserIcon } from '../../ui';
import {
  WhatsappLogsCandidates,
  WhatsappLogsClients,
} from '../../../backend/careo-api';
import {
  USER_TYPE,
  TUserMessage,
  WhatsappMessageUI,
} from '../../../pages/messages';
import { useLocation, useNavigate } from 'react-router-dom';
import ListEmails from '../../email/list-emails.component';
import { downloadFile } from '../../../utils/utils';

type MessageContentProps = {
  receiverType: USER_TYPE;
  selectedUser?: TUserMessage;
  listMessages: WhatsappMessageUI[];
  listEmails: any[];
  sendEmail: (
    subject: string,
    text: string,
    recipient: string,
    files: File[],
  ) => void;
  sendMessageAndAttachmentWhatsapp: (
    phoneNumber: string,
    message: string,
    attachment?: File,
  ) => void;
};

enum EMessagesFilter {
  WHATSAPP = 'whatsapp',
  EMAIL = 'email',
}

const filters = [EMessagesFilter.WHATSAPP, EMessagesFilter.EMAIL];

export const MessageContent = ({
  selectedUser,
  listMessages,
  listEmails,
  sendEmail,
  sendMessageAndAttachmentWhatsapp,
  receiverType,
}: MessageContentProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const messagesEndRef = useRef<any>(null);

  const [message, setMessage] = useState('');
  const [attachment, setAttachment] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputTitleRef = useRef<HTMLInputElement>(null);
  const [selectedFilter, setSelectedFilter] = useState<EMessagesFilter>();

  const changeFilterTab = (tab: EMessagesFilter) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('messagingFilter', tab);
    navigate({
      search: searchParams.toString(),
    });
  };

  const onMessageChange = (e: any) => {
    setMessage(e.target.value ?? '');
  };

  const submitMessage = () => {
    if (attachment) {
      if (attachment.size > +process.env.MAXIMUM_FILE_SIZE! * 1024 * 1024) {
        alert(`File size is bigger than ${process.env.MAXIMUM_FILE_SIZE} MB!`);
        return;
      }
      if (fileInputRef.current) fileInputRef.current.value = '';
      if (fileInputTitleRef.current) fileInputTitleRef.current.innerText = '';
    }
    sendMessageAndAttachmentWhatsapp(
      selectedUser?._id ?? '',
      message ?? '',
      attachment ?? undefined,
    );
    setAttachment(null);
    setMessage('');
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [listMessages]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const messagingFilter = queryParams.get('messagingFilter');
    if (
      Object.values(EMessagesFilter).includes(
        messagingFilter as EMessagesFilter,
      )
    ) {
      setSelectedFilter(messagingFilter as EMessagesFilter);
    } else setSelectedFilter(EMessagesFilter.WHATSAPP);
  }, [location.search]);

  if (!selectedUser) {
    return (
      <MessageContentContainer>
        <div className="no-conversation">No conversation selected</div>
      </MessageContentContainer>
    );
  }

  return (
    <MessageContentContainer>
      <div className="header-conversation">
        <div className="profile-image">
          <a
            href={`/${
              receiverType === USER_TYPE.CANDIDATE
                ? ERoute.CANDIDATES
                : ERoute.CLIENTS
            }/${selectedUser._id}`}
            target="_blank"
            rel="noreferrer"
          >
            <UserIcon
              firstName={selectedUser.firstName}
              lastName={selectedUser.lastName}
            />
          </a>
        </div>
        <div className="info-messenger">
          <a
            href={`/${
              receiverType === USER_TYPE.CANDIDATE
                ? ERoute.CANDIDATES
                : ERoute.CLIENTS
            }/${selectedUser._id}`}
            target="_blank"
            rel="noreferrer"
          >
            <div className="name">
              {selectedUser
                ? selectedUser.firstName + ' ' + selectedUser.lastName
                : '-'}
            </div>
          </a>
          {/* <div className="status">
            Last message : '-'
          </div> */}
        </div>
        <div className="more-options">
          <DotsIcon />
        </div>
      </div>
      <div className="filter-container">
        {filters.map((el) => (
          <div
            className={`filter-item ${el === selectedFilter && 'active'}`}
            onClick={() => changeFilterTab(el)}
          >
            {el}
          </div>
        ))}
      </div>
      {selectedFilter === EMessagesFilter.WHATSAPP ? (
        <>
          <div className="content-conversation">
            {listMessages.length ? (
              <>
                {listMessages.map((el) => {
                  const messageDiv = [];
                  if (el.message) {
                    messageDiv.push(
                      <div
                        className={`message-item ${
                          el.sender === 'recruiter' ? 'sent' : 'reply'
                        }`}
                        key={el._id}
                        title={el.message}
                      >
                        <div>{el.message}</div>
                      </div>,
                    );
                  }

                  if (el.hostedFile) {
                    messageDiv.push(
                      <div
                        className={`message-item ${
                          el.sender === 'recruiter' ? 'sent' : 'reply'
                        }`}
                        key={el._id}
                        title={el.hostedFile?.fileName}
                      >
                        <div>
                          {el.isLoading ? (
                            'attachment file is sending ...'
                          ) : (
                            <div
                              onClick={() => downloadFile(el.hostedFile!)}
                              className="file-downloadable"
                            >
                              <DownloadIcon /> {el.hostedFile?.fileName}
                            </div>
                          )}
                        </div>
                      </div>,
                    );
                  }

                  return messageDiv;
                })}
              </>
            ) : (
              <div className="no-conversation">No messages yet</div>
            )}

            <div ref={messagesEndRef} />
          </div>
          <div className="input-area">
            <div className="attach-button">
              <button
                style={{ background: 'none', border: 'none' }}
                onClick={() => fileInputRef.current?.click()}
              >
                <AttachFileIcon />
                <span ref={fileInputTitleRef} />
              </button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={(event) => {
                  if (event.target.files) {
                    setAttachment(event.target.files[0]);
                    fileInputTitleRef.current!.innerText =
                      event.target?.files?.[0]?.name ?? '';
                  } else setAttachment(null);
                }}
              />
            </div>
            <div className="input-container">
              <Textarea
                placeholder="Write message here ..."
                value={message}
                onChange={onMessageChange}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    submitMessage();
                  }
                }}
              />
            </div>
            <div className="send-button" onClick={submitMessage}>
              <RightArrowIcon />
            </div>
          </div>
        </>
      ) : (
        <ListEmails
          listEmails={listEmails}
          sendEmail={sendEmail}
          selectedUser={selectedUser}
        />
      )}
    </MessageContentContainer>
  );
};
