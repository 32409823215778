import {
  Application,
  Candidate,
  CandidatesList,
  Client,
  ExtendedCandidate,
  HostedFile,
  Job,
  Payroll,
} from '../../backend/careo-api';

export type TSortValues = '+' | '-' | '';
export const onSelectSort = <T>(
  key: T,
  setSort: React.Dispatch<
    React.SetStateAction<{ key: any; value: TSortValues }>
  >,
) => {
  setSort((prev) => {
    let value: '+' | '-' | '' = '';
    if (prev.key === key) {
      if (prev.value === '') {
        value = '+';
      } else if (prev.value === '+') {
        value = '-';
      } else if (prev.value === '-') {
        value = '';
      }
    } else {
      value = '+';
    }
    return { key, value };
  });
};

export const sortCandidates = (
  candidates: Candidate[] | ExtendedCandidate[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = candidates.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;
    if (sort.key === 'county' || sort.key === 'region') {
      keyA = a?.address?.[sort.key] ?? '';
      keyB = b?.address?.[sort.key] ?? '';
    } else {
      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];
    }

    if (typeof keyA !== 'string' && typeof keyA !== 'number') keyA = '';
    if (typeof keyB !== 'string' && typeof keyB !== 'number') keyB = '';

    if (typeof keyA === 'number') {
      keyA = Number(keyA ?? 0);
      keyB = Number(keyB ?? 0);
      if (sort.value === '+') {
        return keyA - keyB;
      } else {
        return keyB - keyA;
      }
    }

    const result = keyA.localeCompare(keyB.toString());
    return sort.value === '+' ? result : -result;
  });

  return sortedData;
};

export const sortCandidatesLists = (
  candidatesLists: CandidatesList[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = candidatesLists.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;

    keyA = (a as any)?.[sort.key];
    keyB = (b as any)?.[sort.key];

    if (typeof keyA !== 'string') keyA = '';
    if (typeof keyB !== 'string') keyB = '';

    const result = keyA.localeCompare(keyB);
    return sort.value === '+' ? result : -result;
  });

  return sortedData;
};

export const sortClients = (
  clients: Client[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = clients.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;
    if (sort.key === 'recruiter-firstName') {
      const newKey = sort.key.includes('recruiter-')
        ? (sort.key.split('recruiter-')[1] as any)
        : sort.key;
      keyA = (a?.recruiter as any)?.[newKey] ?? '';
      keyB = (b?.recruiter as any)?.[newKey] ?? '';
    } else if (sort.key === 'region' || sort.key === 'county') {
      keyA = (a?.address as any)?.[sort.key] ?? '';
      keyB = (b?.address as any)?.[sort.key] ?? '';
    } else {
      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];
    }

    if (typeof keyA !== 'string') keyA = '';
    if (typeof keyB !== 'string') keyB = '';

    const result = keyA.localeCompare(keyB);
    return sort.value === '+' ? result : -result;
  });

  return sortedData;
};

export const sortVacancies = (
  jobs: Job[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = jobs.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;
    if (sort.key === 'client-firstName' || sort.key === 'clientSiteName') {
      const newKey = sort.key.includes('client-')
        ? (sort.key.split('client-')[1] as any)
        : sort.key;
      keyA = (a?.client as any)?.[newKey] ?? '';
      keyB = (b?.client as any)?.[newKey] ?? '';
    } else if (sort.key === 'recruiter-firstName') {
      const newKey = sort.key.includes('recruiter-')
        ? (sort.key.split('recruiter-')[1] as any)
        : sort.key;
      keyA = (a?.recruiter as any)?.[newKey] ?? '';
      keyB = (b?.recruiter as any)?.[newKey] ?? '';
    } else if (sort.key === 'region') {
      keyA = (a?.client as any)?.address?.[sort.key] ?? '';
      keyB = (b?.client as any)?.address?.[sort.key] ?? '';
    } else {
      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];
    }

    if (typeof keyA !== 'string') keyA = '';
    if (typeof keyB !== 'string') keyB = '';

    const result = keyA.localeCompare(keyB);
    return sort.value === '+' ? result : -result;
  });

  return sortedData;
};

export const sortPlacements = (
  placements: Application[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = placements.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;
    if (sort.key === 'firstName') {
      keyA = a?.candidate?.[sort.key] ?? '';
      keyB = b?.candidate?.[sort.key] ?? '';
    } else if (sort.key === 'client-firstName' || sort.key === 'clientName') {
      const newKey = sort.key.includes('client-')
        ? (sort.key.split('client-')[1] as any)
        : sort.key;
      keyA = (a?.job?.client as any)?.[newKey] ?? '';
      keyB = (b?.job?.client as any)?.[newKey] ?? '';
    } else if (sort.key === 'grade') {
      keyA = (a?.job as any)?.[sort.key] ?? '';
      keyB = (b?.job as any)?.[sort.key] ?? '';
    } else if (sort.key === 'availableFrom' || sort.key === 'availableTo') {
      keyA = new Date((a as any)?.[sort.key]);
      keyB = new Date((b as any)?.[sort.key]);
      const result = keyA > keyB ? 1 : keyA === keyB ? 0 : -1;
      return sort.value === '+' ? result : -result;
    } else {
      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];
    }

    if (typeof keyA !== 'string') keyA = '';
    if (typeof keyB !== 'string') keyB = '';

    const result = keyA.localeCompare(keyB);
    return sort.value === '+' ? result : -result;
  });

  return sortedData;
};

export const sortPayrolls = (
  payrolls: Payroll[],
  sort: { key: string; value: '+' | '-' | '' },
) => {
  const sortedData = payrolls.sort((a, b) => {
    if (!sort.key || !sort.value) return 0;

    let keyA, keyB;
    if (sort.key === 'recruiter-firstName') {
      const newKey = sort.key.includes('recruiter-')
        ? (sort.key.split('recruiter-')[1] as any)
        : sort.key;
      keyA = (a?.recruiter as any)?.[newKey] ?? '';
      keyB = (b?.recruiter as any)?.[newKey] ?? '';
    } else {
      keyA = (a as any)?.[sort.key];
      keyB = (b as any)?.[sort.key];
    }

    if (typeof keyA !== 'string') keyA = '';
    if (typeof keyB !== 'string') keyB = '';

    const result = keyA.localeCompare(keyB);
    return sort.value === '+' ? result : -result;
  });

  return sortedData;
};

export type TSortDocument = 'fileName' | 'timestamp' | '';

export const sortDocuments = (
  documents: HostedFile[],
  sort: { key: TSortDocument; value: '+' | '-' | '' },
) => {
  const { key, value } = sort;

  if (!key || !value || !documents.length) {
    return documents;
  }

  const sortedData = documents.slice().sort((a, b) => {
    let keyA: any, keyB: any;

    switch (key) {
      case 'fileName':
        keyA = a.fileName;
        keyB = b.fileName;
        break;
      case 'timestamp':
        keyA = a.timestamp;
        keyB = b.timestamp;
        break;
      default:
        keyA = '';
        keyB = '';
        break;
    }

    if (typeof keyA !== 'string' && typeof keyA !== 'number') keyA = '';
    if (typeof keyB !== 'string' && typeof keyB !== 'number') keyB = '';

    const result = keyA.localeCompare(keyB, undefined, { numeric: true });

    return value === '+' ? result : -result;
  });

  return sortedData;
};
