import React, { useEffect, useState } from 'react';
import { VacanciesToDoListContainer } from './vacancies-todo.style';
import {
  AddCandidateVacancy,
  Button,
  CardContainer,
  SearchInput,
  SubTitlePage,
  TitlePage,
  UserIcon,
} from '../../../components';
import {
  AttachFileIcon,
  DotsIcon,
  Message2Icon,
  PlusIcon,
} from '../../../icons';
import {
  Application,
  Candidate,
  Job,
  UpdateApplicationStatusDto,
} from '../../../backend/careo-api';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  formatDate,
} from '../../../utils';
import { toast } from 'react-toastify';
import { SideModal } from '../../../components/modals';
import { applicationStatusList } from '../../../constants';
import { UpdateCandidateVacancy } from '../../../components/vacancy/update-candidate-vacancy-form/update-candidate-vacancy-form.component';
import { HeaderPageContainer } from '../../../components/header/header.style';

const DraggableArea = ({ todoList, getApplications, index, index2 }: any) => {
  const [active, setActive] = useState(false);

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setActive(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setActive(false);
  };

  const handleDrop = async (e: any, listIndex: number, itemIndex: number) => {
    e.preventDefault();
    const draggedListIndex = e.dataTransfer.getData('listIndex');
    const draggedItemIndex = e.dataTransfer.getData('itemIndex');
    const newTodoList = [...todoList];
    const draggedItem = newTodoList[draggedListIndex].items[draggedItemIndex];

    await AxiosInstance.applications
      .applicationsControllerUpdateApplicationStatus(draggedItem._id, {
        status: applicationStatusList[listIndex].value,
      } as UpdateApplicationStatusDto)
      .then(() => {
        newTodoList[draggedListIndex].items.splice(draggedItemIndex, 1);
        newTodoList[listIndex].items.splice(itemIndex, 0, draggedItem);
        getApplications();
        setActive(false);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
        setActive(false);
      });
  };

  return (
    <div
      className={`draggable-area ${active && 'active'}`}
      onDragOver={handleDragOver}
      onDrop={(e) => handleDrop(e, index, index2)}
      onDragLeave={handleDragLeave}
    >
      <hr />
    </div>
  );
};

export type ApplicationsListGroupByStatus = {
  label: string;
  value: string;
  isDraggableTo?: boolean;
  items: Application[];
};

export const VacanciesToDoListPage = () => {
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateIsModalOpen] =
    useState<Application | null>(null);

  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [applications, setApplications] = useState<Application[]>([]);
  const [vacancy, setVacancy] = useState<Job | null>(null);
  const [todoList, setTodoList] = useState<ApplicationsListGroupByStatus[]>(
    applicationStatusList.map((el) => ({ ...el, items: [] })),
  );

  const onClickNavigateToCandidateProfile = (id: string) => {
    const url = `/${ERoute.CANDIDATES}/${id}`;
    window.open(url, '_blank');
  };

  const getApplications = () => {
    AxiosInstance.applications
      .applicationsControllerGetAllApplications({ jobId: id })
      .then((response) => {
        const applications = response.data as unknown as Application[];
        setApplications(applications);
        const groupedApplications = applications.reduce((acc: any, curr) => {
          const status = curr.status; // Assuming status is a property of the application model
          if (!acc[status]) {
            acc[status] = [];
          }
          acc[status].push(curr);
          return acc;
        }, {});

        setTodoList((prev) => {
          prev = prev.map((el) => ({
            ...el,
            items: groupedApplications[el.value] ?? [],
          }));
          return prev;
        });
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const handleDragStart = (e: any, listIndex: number, itemIndex: number) => {
    e.dataTransfer.setData('listIndex', listIndex);
    e.dataTransfer.setData('itemIndex', itemIndex);
  };

  const getVacancyDetails = () => {
    AxiosInstance.jobs
      .jobsControllerGetJob(id!)
      .then((response) => {
        setVacancy(response as any);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getCandidates = async () => {
    try {
      const response = (
        await AxiosInstance.candidates.candidatesControllerGetAllCandidates()
      ).data as any;
      setCandidates(response);
    } catch (error) {}
  };

  useEffect(() => {
    getVacancyDetails();
    getApplications();
    getCandidates();
  }, []);

  if (!vacancy) {
    return <></>;
  }

  return (
    <>
      <VacanciesToDoListContainer>
        <CardContainer className="vacancies-list-content">
          <HeaderPageContainer>
            <div className="left-container">
              <TitlePage>Vacancies</TitlePage>
              <SubTitlePage>
                List Job {'>'} {vacancy.grade}/{vacancy.specialty}
              </SubTitlePage>
            </div>
            <div className="right-container">
              <SearchInput placeholder="Search what you need" disabled />
              <Button type="primary" onClick={() => setIsModalOpen(true)}>
                <PlusIcon /> Add Candidates
              </Button>
            </div>
          </HeaderPageContainer>
          <div className="todo-list-container">
            {todoList.map((el, index) => (
              <div className="item-container" key={index}>
                <div className="item-header">
                  <div className="item-title">
                    <label>{el.label}</label>
                    <div className="item-total">{el.items.length}</div>
                  </div>
                  <PlusIcon />
                </div>
                <div className="cards-list-container">
                  {el.isDraggableTo ? (
                    <DraggableArea
                      todoList={todoList}
                      getApplications={getApplications}
                      index={index}
                      index2={0}
                    />
                  ) : (
                    <div className="draggable-area false">
                      <hr />
                    </div>
                  )}
                  {el.items
                    .sort(
                      (a, b) =>
                        new Date(a.availableFrom).getTime() -
                        new Date(b.availableFrom).getTime(),
                    )
                    .map((item, index2) => (
                      <>
                        <div
                          className="card-item"
                          key={index2}
                          draggable="true"
                          onDragStart={(e) => handleDragStart(e, index, index2)}
                          onDoubleClick={() =>
                            onClickNavigateToCandidateProfile(
                              item.candidate._id,
                            )
                          }
                        >
                          <div className="card-item-content">
                            <UserIcon
                              firstName={item.candidate?.firstName}
                              lastName={item.candidate?.lastName}
                            />
                            <div className="card-item-info">
                              <div className="name">
                                {item.candidate?.firstName}{' '}
                                {item.candidate?.lastName}
                              </div>
                              <div className="email">
                                {item.candidate?.email}
                              </div>

                              <div className="date">
                                {formatDate(item.availableFrom)} -{' '}
                                {formatDate(item.availableTo)}
                              </div>
                            </div>
                          </div>
                          <div className="card-item-footer">
                            <div className="card-item-left-footer">
                              <Message2Icon /> 0
                              <AttachFileIcon /> 0
                            </div>
                            <div
                              className="card-item-right-footer"
                              onClick={() => setUpdateIsModalOpen(item)}
                            >
                              <DotsIcon />
                            </div>
                          </div>
                        </div>
                        {el.isDraggableTo ? (
                          <DraggableArea
                            todoList={todoList}
                            getApplications={getApplications}
                            index={index}
                            index2={index2}
                          />
                        ) : (
                          <div className="draggable-area false">
                            <hr />
                          </div>
                        )}
                      </>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </CardContainer>
      </VacanciesToDoListContainer>

      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={'Add Candidate to vacancy'}
      >
        <AddCandidateVacancy
          candidates={candidates}
          job={vacancy}
          onCancel={() => setIsModalOpen(false)}
          onSuccess={() => {
            getApplications();
            setIsModalOpen(false);
          }}
        />
      </SideModal>

      <SideModal
        isOpen={!!isUpdateModalOpen}
        setIsOpen={() => setUpdateIsModalOpen(null)}
        title={'Update Candidate Application'}
      >
        <UpdateCandidateVacancy
          candidates={candidates}
          selectedApplication={isUpdateModalOpen!}
          applications={applications}
          job={vacancy}
          onCancel={() => setUpdateIsModalOpen(null)}
          onSuccess={() => {
            getApplications();
            setUpdateIsModalOpen(null);
          }}
        />
      </SideModal>
    </>
  );
};
