import React from 'react';
import styled from 'styled-components';
import { breakpoint } from '../../constants';

const UserIconContainer = styled.div`
  text-transform: uppercase;
  border-radius: 50px;
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  background-color: #0caf60;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bolder;

  @media (max-width: ${breakpoint.mobile}px) {
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
    font-size: 10px;
  }
`;

type UserIconProps = {
  firstName: string;
  lastName: string;
};

export const UserIcon = ({ firstName, lastName }: UserIconProps) => {
  const getOnlyAlphabet = () => {
    return firstName?.[0] + lastName?.[0];
  };

  return <UserIconContainer>{getOnlyAlphabet()}</UserIconContainer>;
};
