import axios from 'axios';
import { getToken } from './token.utils';

const API_URL = process.env.REACT_APP_API_URL;

export const uploadCandidateFile = async (candidateId: string, file: File) => {
  const token = getToken();
  const formData = new FormData();
  formData.append('file', file);
  const response = await axios.post(
    `${API_URL}/files/upload/candidates/${candidateId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const sendEmailRequest = async (
  recipient: string,
  recipientType: string,
  subject: string,
  text: string,
  files: File[],
) => {
  const token = getToken();
  const formData = new FormData();
  formData.append('recipient', recipient);
  formData.append('recipientType', recipientType);
  formData.append('subject', subject);
  formData.append('text', text);
  files.forEach((file) => {
    formData.append('files', file);
  });

  const response = await axios.post(
    `${API_URL}/emails/outlook/send-email`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `bearer ${token}`,
      },
    },
  );

  return response.data;
};

export const sendWhatsappRequest = async (
  recipientId: string,
  recipientType: 'client' | 'candidate',
  message: string,
  file?: File,
) => {
  try {
    const token = getToken();
    const formData = new FormData();
    formData.append('recipientId', recipientId);
    formData.append('recipientType', recipientType);
    formData.append('message', message);

    if (file) {
      formData.append('file', file);
    }

    const response = await axios.post(
      `${API_URL}/whatsapp/send-message`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `bearer ${token}`,
        },
      },
    );

    return response.data;
  } catch (error) {}
};
