export const PagesUrls = {
  DASHBOARD: { Index: '/candidates' },
  AUTHENTICATION: { Login: '/login' },
  CLIENTS: { Index: '/clients', Details: '/clients/:id' },
  CANDIDATES: { Index: '/candidates', Details: '/candidates/:id' },
  CANDIDATES_COMPLIANCE: {
    Index: '/candidates',
    Details: '/candidates/:id',
    Documents: '/candidates/:id/documents',
    Compliance: '/candidates/:id/compliance',
    ComplianceDetails: '/candidates/:id/compliance/:type',
  },
  SHARED_CANDIDATES: {
    Index: '/shared-candidates',
    Details: '/shared-candidates/:id',
  },
  VACANCIES: {
    Index: 'vacancies',
    Details: '/vacancies/:id',
    Applications: 'vacancies/:id/applications',
  },
  PAYROLLS: { Index: '/payrolls', Details: '/payrolls/:id' },
  PLACEMENTS: { Index: '/placements', Details: '/placements/:id' },
  CALENDAR: { Index: '/calendar', Details: '/calendar/:id' },
  MESSAGES: {
    Index: '/messages',
    WildCard: '/messages/*',
    Candidate: '/messages/candidate',
    Client: '/messages/client',
  },
  SETTING: { Index: '/settings', Type: '/settings/:type' },
  TIMESHEET: {
    Client: '/timesheet/:token/client',
    Download: '/timesheet/:token/client/download',
    Candidate: '/timesheet/:token/candidate',
  },
};
