import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../ui';
import {
  AttachFileIcon,
  CalendarIcon,
  CancelIcon,
  DownloadIcon,
} from '../../icons';
import { getTimeSince } from '../../utils';
import { EmailsMessageUi, TUserMessage } from '../../pages/messages';
import { downloadFile } from '../../utils/utils';

type ListEmailsProps = {
  selectedUser?: TUserMessage;
  sendEmail: (
    subject: string,
    text: string,
    recipient: string,
    files: File[],
  ) => void;
  listEmails: EmailsMessageUi[];
};

const ListEmails = ({
  listEmails,
  sendEmail,
  selectedUser,
}: ListEmailsProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [isCreateEmailOpen, setIsCreateEmailOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [attachments, setAttachments] = useState<File[]>([]);

  const submitEmail = () => {
    if (subject.length && body.length) {
      sendEmail(subject, body, selectedUser?.email ?? '', attachments);
      setSubject('');
      setBody('');
      setIsCreateEmailOpen(false);
      setAttachments([]);
    }
  };

  const removeAttachment = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      {isCreateEmailOpen ? (
        <>
          <div className="content-conversation">
            <div className="email-form">
              <div className="email-title">
                <label htmlFor="">Subject : </label>
                <input
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="write title here please ..."
                />
              </div>
              <div className="email-attachments">
                <button
                  style={{ background: 'none', border: 'none' }}
                  onClick={() => fileInputRef.current?.click()}
                >
                  <AttachFileIcon />
                </button>
                <div className="attachments-list">
                  {!attachments.length ? (
                    'No attachments'
                  ) : (
                    <>
                      {attachments.map((el, index) => (
                        <div className="attachment-file">
                          <div>{el.name}</div>
                          <CancelIcon onClick={() => removeAttachment(index)} />
                        </div>
                      ))}
                    </>
                  )}
                </div>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    if (event.target.files) {
                      const files = Array.from(event.target.files);
                      setAttachments((prev) => [...prev, ...files]);
                    }
                  }}
                  multiple
                />
              </div>
              <div className="email-content">
                <textarea
                  name=""
                  id=""
                  rows={10}
                  value={body}
                  onChange={(e) => setBody(e.target.value)}
                  placeholder="write the content here please ..."
                ></textarea>
              </div>
            </div>
          </div>
          <div className="footer-email">
            <Button type="default" onClick={() => setIsCreateEmailOpen(false)}>
              Cancel
            </Button>
            <Button type="primary" onClick={submitEmail}>
              Send
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="content-conversation">
            {listEmails.length ? (
              <>
                {listEmails.map((el) => {
                  return (
                    <div
                      className={`email-item ${el.isReply ? 'reply' : 'sent'}`}
                      key={el._id}
                    >
                      <div className="email-title">{el.subject}</div>
                      <br />
                      <div className="email-attachment-file-container">
                        {el?.hostedFiles?.map((el, index) => (
                          <div
                            className="attachment-file"
                            onClick={() => downloadFile(el)}
                            id={el._id}
                          >
                            <DownloadIcon /> {el.fileName}
                          </div>
                        ))}
                      </div>
                      <div
                        className="email-content"
                        dangerouslySetInnerHTML={{ __html: el.text }}
                      />

                      <div className="email-time">
                        <CalendarIcon /> {getTimeSince(el.timestamp)} ago
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="no-conversation">No emails yet</div>
              </>
            )}
          </div>
          <div className="footer-email">
            <Button type="primary" onClick={() => setIsCreateEmailOpen(true)}>
              Create
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default ListEmails;
