import styled from 'styled-components';
import { breakpoint } from '../../constants';

export const SettingsContainer = styled.div`
  .header-page {
  }
  .content-page {
    display: flex;
    gap: 24px;
    .left-container {
      padding: 24px;
      width: 300px;

      .setting-list-items {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .setting-item {
          display: flex;
          align-items: center;
          gap: 10px;
          padding: 16px;
          cursor: pointer;
          svg > path {
            fill: #a0aec0;
          }
          &:hover,
          &.active {
            background-color: #f8f8f8;
            border-radius: 10px;
            color: #27a376;
          }
        }
      }
    }
    .right-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .settings-title-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .divider {
        height: 1px;
        background: #f1f2f4;
      }
      .settings-content {
      }
    }
  }

  .days-container {
    label {
      min-width: 200px;
      max-width: 200px;
    }
    .options-container {
      flex-wrap: wrap;
      .option-container {
        width: unset;
      }
    }
  }

  .form-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .settings-content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .form-actions {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .content-page {
      flex-direction: column;
      gap: 5px;
      .left-container {
        padding: 5px;
        width: 100%;
        .setting-list-items {
          flex-wrap: wrap;
          flex-direction: unset;
          gap: 5px;
          .setting-item {
            width: fit-content;
            gap: 5px;
            padding: 5px 10px;
          }
        }
      }
      .right-container {
        gap: 10px;
        .settings-title-content {
        }
        .divider {
        }
        .settings-content {
        }
      }
    }

    .days-container {
      label {
        min-width: 200px;
        max-width: 200px;
      }
      .options-container {
        flex-wrap: wrap;
        .option-container {
          width: unset;
        }
      }
    }

    .form-actions {
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;
