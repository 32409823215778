import React from 'react';
import { Table } from '../../ui';
import { daysTitleList } from '../../../constants';
import { isDateIncluded } from '../../../utils';
import { EAvailabilityStatus } from './availability.component';
import { Availability } from '../../../backend/careo-api';

type AvailabilityCalendarProps = {
  weeksDay: {
    day: number;
    label: string;
    week: number;
    month: number;
    year: number;
  }[][];
  availabilities: Availability[];
};
const AvailabilityCalendar = ({
  weeksDay,
  availabilities,
}: AvailabilityCalendarProps) => {
  return (
    <Table className="calendar calendar-row">
      <thead>
        <tr>
          {daysTitleList.map((el) => (
            <th key={el.value}>{el.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {weeksDay.map((days, index) => (
          <tr key={index}>
            {days.map((el) => {
              const cellDate = new Date(
                Date.UTC(el.year, el.month - 1, el.day),
              );
              const availableDay = availabilities.find((availableTime) =>
                isDateIncluded(
                  cellDate,
                  new Date(availableTime?.date.split('T')[0]),
                ),
              );
              return (
                <td
                  key={el.day}
                  className={el.label !== 'currentMonth' ? 'disabled' : ''}
                >
                  <div
                    className={`day-container ${
                      availableDay?.status === EAvailabilityStatus.AVAILABLE
                        ? 'available'
                        : 'booked'
                    }`}
                  >
                    <div className="day-number">{el.day}</div>
                    {availableDay?.availabilityTime === 'fullDay' ? (
                      <div className="fullDay active"></div>
                    ) : (
                      <>
                        <div
                          className={`morning ${
                            availableDay?.availabilityTime === 'morning'
                              ? 'active'
                              : ''
                          }`}
                        ></div>
                        <div
                          className={`afternoon ${
                            availableDay?.availabilityTime === 'afternoon'
                              ? 'active'
                              : ''
                          }`}
                        ></div>
                      </>
                    )}
                  </div>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default AvailabilityCalendar;
