import { UseFormSetError, UseFormSetFocus } from 'react-hook-form';
import { AxiosInstance, AxiosInstanceErrorResponse } from './axios.utils';
import { mimeTypes } from '../constants';
import { toast } from 'react-toastify';
import { HostedFile } from '../backend/careo-api';

export var GBPFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const handleFormsServerErrors = (
  error: AxiosInstanceErrorResponse,
  setError: UseFormSetError<any>,
  setFocus: UseFormSetFocus<any>,
) => {
  if (error.status === 400) {
    const serverErrors = error.message as unknown as Record<string, string>;
    Object.keys(serverErrors).forEach((fieldName) => {
      setError(
        fieldName === 'whatsappId' ? 'phoneNumber' : (fieldName as any),
        {
          type: 'validate',
          message: serverErrors[fieldName],
        },
      );
      setFocus(fieldName as any);
    });
  }
};

export const downloadFile = (file: HostedFile) => {
  AxiosInstance.files
    .filesControllerDownloadFile(file._id, { format: 'blob' })
    .then((response: any) => {
      const blob = new Blob([response]);
      const url = window.URL.createObjectURL(blob);

      const extension = mimeTypes[response.type] || 'pdf';
      const fileNameWithoutExtension = file.fileName.split('.')[0];
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${fileNameWithoutExtension || 'downloaded_file'}.${extension}`,
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error: AxiosInstanceErrorResponse) => {
      toast.error(error.message);
    });
};
