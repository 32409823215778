import { StyleSheet } from '@react-pdf/renderer';

export const invoicePdfStyle = (StyleSheet as any).create({
  page: {
    fontFamily: 'Helvetica',
    padding: 20,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    marginBottom: 20,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  company: {
    fontSize: 10,
    marginBottom: 3,
  },
  label: {
    fontWeight: 'bold',
  },
  value: {
    marginBottom: 5,
  },

  table: {
    display: 'table',
    width: '100%',
    fontSize: 10,
  },
  tableData: {
    borderStyle: 'solid',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    display: 'table',
    width: '100%',
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableCol: {
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableHeader: {
    borderWidth: 0,
  },

  width10: {
    width: '10%',
  },
  width20: {
    width: '20%',
  },
  width40: {
    width: '40%',
  },
  width60: {
    width: '60%',
  },
});
