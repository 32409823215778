import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../utils';
import { Recruiter } from '../../backend/careo-api';
import { toast } from 'react-toastify';
import { Badge, Button, CardContainer, Table, TitlePage } from '../ui';
import { DeleteIcon, EditIcon, PlusIcon, SortIcon } from '../../icons';
import { SideModal } from '../modals';
import { NewRecruiterForm } from '../recruiter';
import { UpdateRecruiterForm } from '../recruiter/update-recruiter-form/update-recruiter-form.component';

type RecruitersListProps = {};

export const RecruitersList = ({}: RecruitersListProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [entityToUpdate, setEntityToUpdate] = useState<Recruiter | null>(null);
  const [recruitersList, setRecruitersList] = useState<Recruiter[]>([]);

  const onClickEdit = (recruiter: Recruiter) => {
    setEntityToUpdate(recruiter);
  };

  const onClickDeleteRecruiter = async (id: string) => {
    try {
      await AxiosInstance.recruiters.recruitersControllerDeleteRecruiter(id);
      toast.success('Recruiter deleted successfully');
      getRecruiters();
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const getRecruiters = async () => {
    AxiosInstance.recruiters
      .recruitersControllerGetAllRecruiters()
      .then((response) => {
        setRecruitersList(response.data as unknown as Recruiter[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getRecruiters();
  }, []);

  return (
    <>
      <CardContainer className="right-container">
        <div className="settings-title-content">
          <TitlePage>Team Access</TitlePage>
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            <PlusIcon />
            Add New
          </Button>
        </div>
        <hr />
        <div className="settings-content">
          <div className="data-table-container">
            <Table>
              <thead>
                <tr>
                  <th>
                    <div>
                      <label>Team Member</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label>Email Address</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label>Role</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th>
                    <div>
                      <label>Active</label>
                      <SortIcon />
                    </div>
                  </th>
                  <th className="action-th">
                    <div>Action</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {recruitersList.map((recruiter) => (
                  <tr key={(recruiter as any)._id}>
                    <td>
                      <div className="name-item">
                        {recruiter?.firstName} {recruiter?.lastName}
                      </div>
                    </td>
                    <td>{recruiter?.email}</td>
                    <td style={{ textTransform: 'capitalize' }}>
                      {recruiter?.role}
                    </td>
                    <td>
                      <Badge text={'Active'} type={'success'} />
                    </td>
                    <td>
                      <div className="action-item">
                        <div
                          className="edit-icon"
                          onClick={() => onClickEdit(recruiter)}
                        >
                          <EditIcon />
                        </div>
                        <div
                          className="delete-icon"
                          onClick={() => onClickDeleteRecruiter(recruiter._id)}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </CardContainer>
      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title={'New Recruiter'}
      >
        <NewRecruiterForm
          setIsOpen={setIsModalOpen}
          getRecruiters={getRecruiters}
        />
      </SideModal>

      <SideModal
        isOpen={!!entityToUpdate}
        setIsOpen={() => setEntityToUpdate(null)}
        title={'Update Recruiter'}
      >
        <UpdateRecruiterForm
          setIsOpen={() => setEntityToUpdate(null)}
          recruiter={entityToUpdate as Recruiter}
          getRecruiters={getRecruiters}
        />
      </SideModal>
    </>
  );
};

export default RecruitersList;
