import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  NewPlacementForm,
  SearchInput,
  Select,
  SubTitlePage,
  Table,
  TitlePage,
} from '../../../components';
import {
  DeleteIcon,
  EditIcon,
  EyeIcon,
  LeftArrowIcon,
  PlusIcon,
  RightArrowIcon,
  SortIcon,
} from '../../../icons';
import {
  TUIType,
  getGradesOfSelectedLevel,
  jobLevelWithGrades,
} from '../../../constants';
import {
  AxiosInstance,
  ERoute,
  TFilterPlacement,
  filterPlacements,
  formatDate,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortPlacements,
} from '../../../utils';
import { SideModal } from '../../../components/modals';
import { useNavigate } from 'react-router-dom';
import { Application } from '../../../backend/careo-api';
import { EApplicationStatus } from '../../../components/vacancy/add-candidate-vacancy-form/add-candidate-vacancy-form.component';
import { toast } from 'react-toastify';
import { UpdatePlacementForm } from '../../../components/placement/update-placement-form/update-placement-form.component';
import { ConfirmModal } from '../../../components/modals/confirm-modal/confirm-modal.component';
import { HeaderPageContainer } from '../../../components/header/header.style';
import { getStatus } from '../../../utils/status.utils';
import { MultipleSelect } from '../../../components/ui/inputs/select/multiple-select.component';

const numberOfItems = 8;

export const PlacementsListPage = () => {
  const navigate = useNavigate();

  const [placements, setPlacements] = useState<Application[]>([]);
  const [applicationsList, setApplicationsList] = useState<Application[]>([]);
  const [placementsList, setPlacementsList] = useState<Application[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [placementToUpdate, setPlacementToUpdate] = useState<Application>();

  const [filter, setFilter] = useState<TFilterPlacement>({
    search: '',
    levels: [],
    grades: [],
  });
  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const onClickAddNew = () => {
    setIsModalOpen(true);
  };

  const onClickEdit = (placement: Application) => {
    setPlacementToUpdate(placement);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.PLACEMENTS}/${id}`);
  };

  const getPlacements = async () => {
    try {
      const result =
        (await AxiosInstance.applications.applicationsControllerGetAllPlacements()) as unknown as Application[];
      setPlacements([...result]);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };
  const [recordToDelete, setRecordToDelete] = useState<Application>();

  const deletePlacement = useCallback(async () => {
    try {
      await AxiosInstance.applications.applicationsControllerDeleteApplication(
        recordToDelete?._id!,
      );
      toast.success('Placement Removed successfully');
      getPlacements();
      setRecordToDelete(undefined);
    } catch (error) {
      toast.error('Something went wrong');
    }
  }, [recordToDelete]);

  const getApplications = async () => {
    const response = (
      await AxiosInstance.applications.applicationsControllerGetAllApplications()
    ).data as unknown as Application[];

    const filteredResponse = response.filter(
      (el) =>
        el.status !== EApplicationStatus.rejected &&
        el.status !== EApplicationStatus.placement,
    );
    setApplicationsList(filteredResponse);
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(placementsList, currentPage, numberOfItems);
  }, [placementsList, currentPage]);

  useEffect(() => {
    const filteredData = filterPlacements(placements, filter);
    const sortedData = sortPlacements(filteredData, sort);
    setPlacementsList(sortedData);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, numberOfItems));
  }, [placements, filter, sort]);

  useEffect(() => {
    getPlacements();
    getApplications();
  }, []);

  return (
    <>
      <CardContainer>
        <HeaderPageContainer>
          <div className="left-container">
            <TitlePage>Placements</TitlePage>
            <SubTitlePage>Manage your Placement</SubTitlePage>
          </div>
          <div className="right-container">
            <Button type="primary" onClick={onClickAddNew}>
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container">
          <SearchInput
            placeholder="Search placement"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
          />
          <MultipleSelect
            placeholder="All Levels"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, levels: values ?? [] }));
            }}
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
          />
          <MultipleSelect
            placeholder="All Grades"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, grades: values ?? [] }));
            }}
            options={getGradesOfSelectedLevel(filter.levels).map((el) => ({
              label: el,
              value: el,
            }))}
            disabled={!filter.levels.length && !filter.grades.length}
          />
          <Select options={[]} disabled placeholder="All Status" />
        </div>
        <div className="data-table-container">
          <Table>
            <thead>
              <tr>
                <th className="checkbox-table">
                  <input type="checkbox" />
                </th>
                <th onClick={() => onSelectSort('firstName', setSort)}>
                  <div>
                    <label>Employee Name</label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('grade', setSort)}>
                  <div>
                    <label>Placement</label>
                    <SortIcon value={sort.key === 'grade' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('availableFrom', setSort)}>
                  <div>
                    <label>From</label>
                    <SortIcon
                      value={sort.key === 'availableFrom' ? sort.value : ''}
                    />
                  </div>
                </th>

                <th onClick={() => onSelectSort('availableTo', setSort)}>
                  <div>
                    <label>To</label>
                    <SortIcon
                      value={sort.key === 'availableTo' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('clientName', setSort)}>
                  <div>
                    <label>Client Name</label>
                    <SortIcon
                      value={sort.key === 'clientName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('client-firstName', setSort)}>
                  <div>
                    <label>Lead Contact</label>
                    <SortIcon
                      value={sort.key === 'client-firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th>
                  <div>
                    <label>Status</label>
                    <SortIcon />
                  </div>
                </th>
                <th>
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((placement) => {
                return (
                  <tr key={placement?._id}>
                    <td className="checkbox-table">
                      <input type="checkbox" />
                    </td>
                    <td>
                      <div className="name-item">
                        <div>
                          <div>
                            {placement.candidate?.firstName}{' '}
                            {placement.candidate?.lastName}
                          </div>
                          <div className="email">
                            {placement.candidate?.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="name-item">
                        <div>
                          <div> {placement.job?.grade}</div>
                          <div className="email">
                            {placement.job?.specialty}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>{formatDate(placement.availableFrom)}</td>
                    <td>{formatDate(placement.availableTo)}</td>
                    <td>{placement.job?.client?.clientName}</td>
                    <td>
                      <div className="name-item">
                        <div>
                          <div>
                            {placement.job?.client?.firstName}{' '}
                            {placement.job?.client?.lastName}
                          </div>
                          <div className="email">
                            {placement.job?.client?.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {placement.fee ? (
                        <Badge text={'Valid'} type={getStatus('Active')} />
                      ) : (
                        <Badge text={'Invalid'} type={getStatus('On leave')} />
                      )}
                    </td>
                    <td>
                      <div className="action-item">
                        <div
                          className="view-icon"
                          onClick={() => onClickView(placement?._id)}
                        >
                          <EyeIcon />
                        </div>
                        <div
                          className="edit-icon"
                          onClick={() => onClickEdit(placement)}
                        >
                          <EditIcon />
                        </div>

                        <div
                          className="delete-icon"
                          onClick={() => setRecordToDelete(placement)}
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className="pagination-container">
          <div className="navigation-container">
            <div
              className={`left-arrow ${currentPage === 1 && 'disabled'}`}
              onClick={() => navigatePagination(currentPage - 1)}
            >
              <LeftArrowIcon />
            </div>
            <div className="pages-list">
              {Array.from({ length: totalPages }, (_, index) => index + 1).map(
                (el) => (
                  <div
                    className={`page-number ${el === currentPage && 'active'}`}
                    onClick={() => navigatePagination(el)}
                  >
                    {el}
                  </div>
                ),
              )}
            </div>
            <div
              className={`right-arrow ${
                currentPage === totalPages && 'disabled'
              }`}
              onClick={() => navigatePagination(currentPage + 1)}
            >
              <RightArrowIcon />
            </div>
          </div>
          <div className="information-container">
            Showing {currentPage} to {totalPages} of {placementsList.length}{' '}
            entries
          </div>
        </div>
      </CardContainer>
      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="New Placement"
      >
        <NewPlacementForm
          setIsOpen={setIsModalOpen}
          getPlacements={getPlacements}
          applicationsList={applicationsList}
        />
      </SideModal>

      <SideModal
        isOpen={!!placementToUpdate}
        setIsOpen={() => setPlacementToUpdate(undefined)}
        title="Update Placement"
      >
        <UpdatePlacementForm
          placementToUpdate={placementToUpdate!}
          onCancel={() => setPlacementToUpdate(undefined)}
          onSuccess={() => {
            getPlacements();
            setPlacementToUpdate(undefined);
          }}
        />
      </SideModal>

      <ConfirmModal
        isOpen={recordToDelete !== undefined}
        title="Delete Placement"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deletePlacement()}
      >
        Do you want to delete this placement
      </ConfirmModal>
    </>
  );
};
