import React from 'react';
import { SelectContainer } from './select.style';
import { Controller, FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

type OptionType = {
  value: string | number;
  label: string;
  isDisabled?: boolean;
};

type FieldTextProps = {
  label?: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  error?: FieldError;
  onClick?: () => void;
  onChange?: (value: string | number) => void;
  options: OptionType[];
  value?: string | number;
  register?: UseFormRegisterReturn<any>;
  control?: any;
};

export const Select = ({
  label,
  value,
  onChange,
  required,
  options,
  register,
  className,
  disabled,
  error,
  placeholder,
  onClick,
  control,
}: FieldTextProps) => {
  return (
    <SelectContainer
      className={`select-container ${className ?? ''}`}
      hasError={!!error}
      onClick={() => onClick?.()}
    >
      {label && (
        <label htmlFor="">
          {label} {required && <span>*</span>}
        </label>
      )}

      {control ? (
        <Controller
          control={control}
          name={register?.name ?? ''}
          render={({
            field: { onChange: fieldOnChange, value: fieldValue },
          }) => (
            <Autocomplete
              value={fieldValue || null}
              getOptionLabel={(option) => {
                return options.find((el) => el.value === option)?.label ?? '';
              }}
              renderOption={(props, option: any) => (
                <li {...props}>{option.label}</li>
              )}
              options={options}
              onChange={(event, target) => {
                let value: any;
                value = (target as OptionType)?.value;
                fieldOnChange(value);
                onChange?.(value);
                register?.onChange?.({
                  target: {
                    value: value,
                    name: register.name,
                  },
                });
              }}
              renderInput={(params) => (
                <TextField
                  className="mui-input"
                  {...params}
                  placeholder={placeholder}
                />
              )}
              disabled={disabled}
              filterOptions={(options: OptionType[], state) => {
                return options.filter((el) =>
                  el.label
                    .toLocaleLowerCase()
                    .includes(state.inputValue.toLocaleLowerCase()),
                );
              }}
            />
          )}
        />
      ) : (
        <Autocomplete
          disablePortal
          options={options}
          onChange={(event, target) => {
            let value: any;
            value = (target as OptionType)?.value;
            register?.onChange?.({
              target: {
                value: value,
                name: register.name,
              },
            });
            onChange?.(value);
          }}
          value={
            value
              ? options.find((el) => el.value === value) ?? undefined
              : undefined
          }
          renderInput={(params) => (
            <TextField
              className="mui-input"
              {...params}
              placeholder={placeholder}
            />
          )}
          disabled={disabled}
        />
      )}
      {error && <span className="error-container">{error?.message}</span>}
    </SelectContainer>
  );
};
