import React from 'react';
import styled from 'styled-components';
import { TUIType, breakpoint, getTypeStyles } from '../../constants';

const ButtonContainer = styled.div<{ type: TUIType }>`
  padding: 15px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 8px;
  font-size: 14px;
  ${(props) => getTypeStyles(props.type)}
  &:not(.disabled):hover {
    cursor: pointer;
    opacity: 0.9;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  &.disabled {
    cursor: not-allowed;
    color: gray;
    border: none;
    background-color: #e9ecef;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    padding: 10px 10px;
    font-size: 10px;
    gap: 5px;
  }
`;

type ButtonProps = {
  type?: TUIType;
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
};

export const Button: React.FC<ButtonProps> = ({
  onClick = () => {},
  children,
  type = 'default',
  disabled,
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      type={type}
      className={disabled ? 'disabled' : ''}
    >
      {children}
    </ButtonContainer>
  );
};
