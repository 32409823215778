import { Job, Notification } from '../backend/careo-api';
import { ERoute } from './routes.utils';

export enum ENotificationType {
  CANDIDATE_MATCH = 'CANDIDATE_MATCH',
  COMPLIANCE_REQUIRED = 'COMPLIANCE_REQUIRED',
  COMPLIANCE_WARNING_EXPIRATION = 'COMPLIANCE_WARNING_EXPIRATION',
  COMPLIANCE_EXPIRED = 'COMPLIANCE_EXPIRED',
  COMPLIANCE_NEW_PARSED_DOCUMENT = 'COMPLIANCE_NEW_PARSED_DOCUMENT',
  AI_VACANCY_CREATED = 'AI_VACANCY_CREATED',
  COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_IN_PROCESS = 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_IN_PROCESS',
  COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_FAILED = 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_FAILED',
  COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_SUCCESS = 'COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_SUCCESS',
  COMPLIANCE_PRO_REG_VALIDATION_IN_PROCESS = 'COMPLIANCE_PRO_REG_VALIDATION_IN_PROCESS',
  COMPLIANCE_PRO_REG_VALIDATION_FAILED = 'COMPLIANCE_PRO_REG_VALIDATION_FAILED',
  COMPLIANCE_PRO_REG_VALIDATION_SUCCESS = 'COMPLIANCE_PRO_REG_VALIDATION_SUCCESS',
  CANDIDATE_REJECT_PLACEMENT = 'CANDIDATE_REJECT_PLACEMENT',
}

export const getNotificationData = (notification: Notification) => {
  let data = notification.data as any;
  let title = '';
  let description = '';
  let url = '';

  switch (notification.type) {
    case ENotificationType.CANDIDATE_REJECT_PLACEMENT:
      title = 'Placement Rejected';
      description = `${data?.candidate?.firstName} ${
        data?.candidate?.lastName
      } has rejected ${(data?.job as Job)?.grade} placement.`;
      url = `/${ERoute.PLACEMENTS}/${data?.placement?._id}`;
      break;
    case ENotificationType.CANDIDATE_MATCH:
      title = 'New Candidate Match';
      description = `${data?.candidate?.firstName} ${data?.candidate?.lastName} has been matched to the open ${data?.job?.specialty} vacancy.`;
      url = `/${ERoute.VACANCIES}/${data?.job?._id}/applications`;
      break;
    case ENotificationType.COMPLIANCE_REQUIRED:
      title = 'Compliance Required';
      description = `${data?.candidate?.firstName} ${data?.candidate?.lastName} needs to complete compliance before the start date.`;
      url = `/${ERoute.CANDIDATES}/${data?.candidate?._id}`;
      break;
    case ENotificationType.COMPLIANCE_WARNING_EXPIRATION:
      title = 'Compliance Expiration Warning';
      description = `${data?.candidate?.firstName} ${data?.candidate?.lastName}'s compliance will expire soon.`;
      url = `/${ERoute.CANDIDATES}/${data?.candidate?._id}`;
      break;
    case ENotificationType.COMPLIANCE_EXPIRED:
      title = 'Compliance Expired';
      description = `${data?.candidate?.firstName} ${data?.candidate?.lastName}'s compliance has expired.`;
      url = `/${ERoute.CANDIDATES}/${data?.candidate?._id}`;
      break;
    case ENotificationType.AI_VACANCY_CREATED:
      title = 'New AI Vacancy Created';
      description = `${data?.vacancy?.name} vacancy for ${data?.vacancy?.client}.`;
      url = `/${ERoute.VACANCIES}/${data?.job?._id}`;
      break;
    case ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_IN_PROCESS:
      title = 'Employment History documents';
      description = 'Validation is in process ...';
      url = `/${ERoute.CANDIDATES}/${data?.candidateId}`;
      break;
    case ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_SUCCESS:
      title = 'Employment History Validation';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${data?.candidateId}`;
      break;
    case ENotificationType.COMPLIANCE_EMPLOYMENT_HISTORY_VALIDATION_FAILED:
      title = 'Failed to validate Employment History documents';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${data?.candidateId}`;
      break;
    case ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_IN_PROCESS:
      title = 'Professional Registry documents';
      description = 'Validation is in process ...';
      url = '';
      break;
    case ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_SUCCESS:
      title = 'Professional Registry Validation';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${data?.candidateId}`;
      break;
    case ENotificationType.COMPLIANCE_PRO_REG_VALIDATION_FAILED:
      title = 'Validation failed';
      // description =
      //   'Failed to validate Professional Registration documents for a candidate';
      description = data?.message || '';
      url = `/${ERoute.CANDIDATES}/${data?.candidateId}`;
      break;
    default:
      title = '';
      description = '';
  }

  if (!title) {
    return null;
  }

  return {
    _id: notification._id,
    title,
    description,
    url,
    isNew: !notification.viewedDate,
    createdDate: new Date(notification.createdDate),
  };
};

export type TNotificationUI = {
  _id: string;
  title: string;
  description: string;
  url: string;
  isNew: boolean;
  createdDate: Date;
};
