import React, { useEffect, useState } from 'react';
import { TitleCard, FieldFile, RadioGroup, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { uploadCandidateFile } from '../../../utils';
import { Compliance, HostedFile } from '../../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SideModal } from '../../modals';
import { SelectFileList } from '../../candidate/select-file/select-file-list.component';
import { DocumentsListComponent } from '../../document/documents-list.component';

type RightWorkProps = {
  compliance: Compliance;
  documents: HostedFile[];
  onClickSubmit: (
    id: string,
    values: any,
    validate?: boolean,
    successMessage?: string,
  ) => void;
  getDocuments: () => void;
  reFetchCompliance?: () => void;
  onUpdateValidation: (id: string, value: string) => void;
};

type TFilesKey = 'passportFiles';

export const RightWork = ({
  compliance,
  documents,
  onClickSubmit,
  getDocuments,
  reFetchCompliance,
  onUpdateValidation,
}: RightWorkProps) => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState<TFilesKey | false>(false);
  const [isValid, setIsValid] = useState<boolean>();

  const { register, getValues, setValue } = useForm<{
    passport: string;
    note: string;
    passportFiles: any[];
  }>();

  const [filesList, setFilesList] = useState<
    { key: TFilesKey; files: any[] }[]
  >([{ key: 'passportFiles', files: [] }]);

  const onClickDelete = (key: TFilesKey, id: string) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files = prev[itemIndex].files.filter(
        (el) => el._id !== id,
      );
      onUpdateCompliance(
        key,
        prev[itemIndex].files,
        'File deleted successfully',
      );
      return [...prev];
    });
  };

  const handleFileChange = async (event: any, key: TFilesKey) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      await uploadCandidateFile(id!, selectedFile)
        .then((response) => {
          event.target.value = '';
          addNewFile(key, response);
        })
        .catch(() => {
          toast.error('Something went wrong');
        });
    } else {
      console.log('No file selected');
    }
  };

  const addNewFile = (key: TFilesKey, file: any) => {
    setFilesList((prev) => {
      const itemIndex = prev.findIndex((el) => el.key === key);
      prev[itemIndex].files.push(file);
      onUpdateCompliance(key, prev[itemIndex].files);
      return [...prev];
    });
  };

  const onUpdateCompliance = (
    key: 'passportFiles' | 'passport' | 'note',
    value: any,
    successMessage?: string,
  ) => {
    onClickSubmit(compliance._id, getValues(), false, successMessage);
  };

  useEffect(() => {
    if (compliance) {
      setValue('passport', (compliance?.metadata as any)?.passport ?? '3');
      setValue('note', (compliance?.metadata as any)?.note ?? '3');
      const passportFiles = (compliance?.metadata as any)?.passportFiles ?? [];
      setValue('passportFiles', passportFiles);
      setFilesList([{ key: 'passportFiles', files: passportFiles }]);
      setIsValid(compliance.isValid);
    }
  }, [compliance]);

  return (
    <>
      <>
        <TitleCard>Passport Identification</TitleCard>
        <RadioGroup
          name="passport"
          options={[
            { label: 'British Passport (incl N.Ireland)', value: '1' },
            { label: 'European Union', value: '2' },
            { label: 'Rest of world ', value: '3' },
          ]}
          register={register('passport')}
          onChange={(value) => {
            onUpdateCompliance('passport', value);
          }}
        />
        <div className="row upload-buttons-container">
          <div className="col-md-6">
            <FieldFile
              label="Upload Passport"
              onChange={(e) => handleFileChange(e, 'passportFiles')}
            />
          </div>
          <div
            className="col-md-6 button-select"
            onClick={() => setIsModalOpen('passportFiles')}
          >
            <Button type="primary">Select from files</Button>
          </div>
        </div>
        <DocumentsListComponent
          documents={
            filesList.find((el) => el.key === 'passportFiles')?.files ?? []
          }
          onClickDelete={(id) => {
            onClickDelete('passportFiles', id);
          }}
          getDocuments={getDocuments}
          onDocumentRename={() => reFetchCompliance?.()}
        />
        <TitleCard>Administer validity checks for YOTI or Trust ID</TitleCard>
        <div className="description">Validation approved</div>
        <RadioGroup
          options={[
            {
              label: 'Pass',
              value: 'true',
            },
            {
              label: 'Fail',
              value: 'false',
            },
          ]}
          name="trust"
          value={isValid?.toString()}
          onChange={(value: any) => onUpdateValidation(compliance._id, value)}
        />
        <TitleCard>Notes</TitleCard>
        <RadioGroup
          options={[
            {
              label: 'British Passport (incl N.Ireland)',
              value: 1,
              description: 'No further action - proceed candidate application ',
            },
            {
              label: 'European Union',
              value: 2,
              description: (
                <>
                  Full EU settlement or Pre settlement status.
                  <br />
                  <br />
                  This is confirmed via a sharecode - provided by candidate
                  Sharecode is verified via government gateway. 
                </>
              ),
            },
            {
              label: 'Rest of the World',
              value: 0,
              description: (
                <>
                  Candidate must demonstrate "right to work status".
                  Qualification = - Limited leave to remain. - Indefinite leave
                  to remain. - Spouse visa. Student visa or tier 1-5 <br />
                  <br />
                  <strong>
                    **UK Home office rules are consistently subject to
                    changee** 
                  </strong>
                </>
              ),
            },
          ]}
          name="note"
          register={register('note')}
          onChange={(value) => {
            onUpdateCompliance('note', value);
          }}
        />
      </>
      <SideModal
        isOpen={!!isModalOpen}
        setIsOpen={setIsModalOpen as any}
        title="Select File"
      >
        <SelectFileList
          fileKey={isModalOpen as any}
          setIsOpen={setIsModalOpen as any}
          documents={documents}
          addNewFile={addNewFile}
        />
      </SideModal>
    </>
  );
};
