import React, { createContext, useState, useContext, useEffect } from 'react';
import { AxiosInstance, getToken, removeToken, setToken } from '../utils';
import { Recruiter } from '../backend/careo-api';

interface AuthContextType {
  user: Recruiter | null;
  setUser: (user: Recruiter | null) => void;
  login: (response: {
    access_token: string;
    firstName: string;
    lastName: string;
  }) => void;
  logout: () => void;
  verifyToken: () => Promise<void>;
  isUserAuthenticated?: boolean;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: () => {},
  login: () => {},
  logout: () => {},
  verifyToken: async () => {},
  isUserAuthenticated: undefined,
  isLoading: true,
});

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useState<Recruiter | null>(null);

  const [isUserAuthenticated, setIsUserAuthenticated] = useState<
    boolean | undefined
  >(undefined);

  const [isLoading, setIsLoading] = useState(true);

  const verifyToken = async () => {
    try {
      if (getToken()) {
        await AxiosInstance.auth
          .authControllerVerifyToken()
          .then((response) => {
            setUser(response as unknown as Recruiter);
            setIsUserAuthenticated(true);
          })
          .catch(() => {
            setUser(null);
            setIsUserAuthenticated(false);
          });
      } else {
        setUser(null);
        setIsUserAuthenticated(false);
      }
    } catch (error) {
      setUser(null);
      setIsUserAuthenticated(false);
    }
    setIsLoading(false);
  };

  const login = (data: {
    access_token: string;
    firstName: string;
    lastName: string;
  }) => {
    setToken(data.access_token);
    verifyToken();
  };

  const logout = () => {
    removeToken();
    setUser(null);
    setIsUserAuthenticated(false);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        login,
        logout,
        verifyToken,
        isUserAuthenticated,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
