import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { Select, TitleCard, FieldText, Button } from '../../ui';
import { useForm, SubmitHandler } from 'react-hook-form';
import { CreateRecruiterDto } from '../../../backend/careo-api';
import { NewRecruiterFormContainer } from './new-recruiter-form.style';
import { AxiosInstance } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddRecruiterSchema } from '../../../utils/validators/recruiter.validator';

type NewRecruiterFormProps = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getRecruiters: () => void;
};

export const NewRecruiterForm = ({
  setIsOpen,
  getRecruiters,
}: NewRecruiterFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateRecruiterDto>({
    resolver: yupResolver(AddRecruiterSchema as any),
  });

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.recruiters
      .recruitersControllerCreateRecruiter(values)
      .then(() => {
        toast.success('Recruiter added successfully');
        getRecruiters();
        onClickClose();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  return (
    <NewRecruiterFormContainer>
      <FieldText label="Role" required value="Recruiter" disabled />
      <TitleCard>Recruiter Details</TitleCard>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('firstName', { required: true })}
            error={errors.firstName}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('lastName', { required: true })}
            error={errors.lastName}
          />
        </div>
      </div>
      <FieldText
        placeholder="Enter here ..."
        label="Email"
        required
        register={register('email', { required: true })}
        error={errors.email}
      />
      <FieldText
        type="phoneNumber"
        placeholder="+442071234567"
        label="Phone Number"
        required
        register={register('phoneNumber', { required: true })}
        error={errors.phoneNumber}
      />

      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Password"
            required
            register={register('password', { required: true })}
            error={errors.password}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Confirm Password"
            required
            register={register('confirmPassword', { required: true })}
            error={errors.confirmPassword}
          />
        </div>
      </div>

      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </NewRecruiterFormContainer>
  );
};
