import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const VacanciesToDoListContainer = styled.div`
  .vacancies-list-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-x: auto;
    .todo-list-container {
      display: flex;
      gap: 24px;
      overflow-x: auto;
      .item-container {
        background-color: #f8f8f8;
        padding: 20px;
        border-radius: 15px;
        min-width: 294px;
        max-width: 294px;

        .item-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .item-title {
            display: flex;
            gap: 10px;
            label {
              font-size: 14px;
            }
            .item-total {
              font-size: 12px;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 6px;
              border: 1px solid #27a376;
              color: #27a376;
            }
          }
          svg {
            path {
              fill: ${(props) => props.theme.text.blackColor};
            }
          }
        }
        .cards-list-container {
          display: flex;
          flex-direction: column;
          height: 100%;
          hr {
            visibility: hidden;
          }
          .draggable-area {
            &:last-child {
              flex: 1;
            }
            hr {
              visibility: hidden;
            }
            &.active {
              hr {
                visibility: visible;
                border: 5px solid ${(props) => props.theme.text.greenColor};
                border-radius: 10px;
                transition: background-color 0.5s ease;
                opacity: 1;
              }
            }
          }
          .card-item {
            background-color: #ffffff;
            padding: 16px;
            border-radius: 16px;
            cursor: pointer;
            .card-item-content {
              display: flex;
              gap: 12px;
              align-items: center;
              margin-bottom: 20px;
              .card-item-info {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                .name {
                  font-size: 14px;
                  font-weight: 600;
                }
                .email {
                  color: #a0aec0;
                  font-size: 12px;
                  font-weight: 400;
                }
                .date {
                  color: #a0aec0;
                  font-size: 10px;
                  font-weight: 400;
                }
              }
            }
            .card-item-footer {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .card-item-left-footer {
                display: flex;
                align-items: center;
                gap: 20px;
                color: #a0aec0;
                svg {
                  margin-right: 5px;
                  path {
                    fill: #a0aec0;
                  }
                }
              }
              .card-item-right-footer {
                cursor: pointer;
                svg {
                  path {
                    fill: #111827;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .vacancies-list-content {
      gap: 10px;
      .todo-list-container {
        gap: 10px;
        .item-container {
          padding: 10px;
          min-width: 170px;
          max-width: 170px;
          .item-header {
            .item-title {
              gap: 5px;
              label {
                font-size: 10px;
              }
              .item-total {
                font-size: 8px;
                width: 15px;
                height: 15px;
              }
            }
          }
          .cards-list-container {
            .draggable-area {
            }
            .card-item {
              padding: 5px;
              .card-item-content {
                gap: 5px;
                margin-bottom: 10px;
                .card-item-info {
                  .name {
                    font-size: 10px;
                  }
                  .email {
                    font-size: 8px;
                  }
                  .date {
                    font-size: 8px;
                  }
                }
              }
              .card-item-footer {
                .card-item-left-footer {
                  gap: 10px;
                  svg {
                    margin-right: 2px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
