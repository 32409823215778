import React, { useEffect, useState } from 'react';
import { Select, Button, FieldText } from '../../ui';
import { useForm } from 'react-hook-form';
import {
  Candidate,
  CreateApplicationDto,
  CreateApplicationDurationDto,
  Job,
} from '../../../backend/careo-api';
import { AddCandidateVacancyContainer } from './add-candidate-vacancy-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { applicationStatusList } from '../../../constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddCandidateVacancySchema } from '../../../utils/validators/vacancy.validator';
import { MinusIcon, PlusIcon } from '../../../icons';
import { handleFormsServerErrors } from '../../../utils/utils';

type AddCandidateVacancyProps = {
  onCancel: () => void;
  onSuccess: () => void;
  candidates: Candidate[];
  job: Job;
};

export enum EApplicationStatus {
  referred = 'referred',
  ai_matched = 'ai_matched',
  rejected = 'rejected',
  shortlist = 'shortlist',
  placement = 'placement',
}

export const AddCandidateVacancy = ({
  onCancel,
  onSuccess,
  candidates,
  job,
}: AddCandidateVacancyProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<CreateApplicationDto>({
    resolver: yupResolver(AddCandidateVacancySchema as any),
  });

  const [durations, setDurations] = useState<CreateApplicationDurationDto[]>([
    {} as CreateApplicationDurationDto,
  ]);

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.applications
      .applicationsControllerCreate({
        ...values,
        jobId: job._id,
      })
      .then(() => {
        toast.success('Candidate added to vacancy successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);

        toast.error(error.message);
      });
  };

  const onClickAddDuration = () => {
    setDurations((prev) => [...prev, {} as CreateApplicationDurationDto]);
  };

  const onClickDeleteDuration = (index: number) => {
    if (durations.length <= 1) {
      return;
    }
    setDurations(() => {
      const tempDurations = getValues('durations');
      tempDurations.splice(index, 1);
      setValue('durations', tempDurations);
      const durationErrors = errors?.durations ?? [];
      durationErrors.splice?.(index, 1);
      setError('durations', durationErrors as any);
      return [...tempDurations];
    });
  };

  return (
    <AddCandidateVacancyContainer>
      <Select
        options={candidates.map((el) => ({
          label: el.firstName + ' ' + el.lastName,
          value: el._id!,
        }))}
        placeholder="Option"
        label="Candidate"
        required
        register={register('candidateId', { required: true })}
        error={errors.candidateId}
      />
      <Select
        options={applicationStatusList.filter((el) => el.isDraggableTo)}
        placeholder="Option"
        label="Status"
        required
        register={register('status', { required: true })}
        error={errors.status}
      />

      {durations.map((_, index) => (
        <div className="row" key={index}>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Start Date"
              required
              type="date"
              register={register(`durations.${index}.availableFrom`)}
              error={errors.durations?.[index]?.availableFrom}
              min={job.vacantFrom.split('T')[0]}
              max={job.vacantTo.split('T')[0]}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="End Date"
              required
              type="date"
              register={register(`durations.${index}.availableTo`)}
              error={errors.durations?.[index]?.availableTo}
              min={job.vacantFrom.split('T')[0]}
              max={job.vacantTo.split('T')[0]}
            />
          </div>
          <div className="col-md-2 delete-button">
            <label htmlFor="">*</label>
            <Button
              type="danger"
              onClick={() => onClickDeleteDuration(index)}
              disabled={durations.length <= 1}
            >
              <MinusIcon />
            </Button>
          </div>
        </div>
      ))}

      <div className="add-duration-container d-flex justify-content-start">
        <Button type="success" onClick={onClickAddDuration}>
          <PlusIcon /> Add New Duration
        </Button>
      </div>

      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </AddCandidateVacancyContainer>
  );
};
