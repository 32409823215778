import React, { useEffect, useMemo, useState } from 'react';
import {
  TimesheetActionContainer,
  TimesheetContainer,
} from './timesheet.style';
import { AppIcon, DeleteIcon } from '../../icons';
import { Button, FieldText, Select, Table } from '../../components';
import { AxiosInstance, formatDate, getTotalWorkingHours } from '../../utils';
import { durations } from '../../constants';
import { AddTimingsDto, Timesheet } from '../../backend/careo-api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const TimesheetCandidatePage = () => {
  const { token } = useParams();

  const [timings, setTimings] = useState<AddTimingsDto[]>([]);
  const [timesheet, setTimesheet] = useState<Timesheet | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const totalOfWorkingHours: string = useMemo(() => {
    return getTotalWorkingHours(timings);
  }, [timings]);

  const getTimesheetDetails = async () => {
    try {
      const result =
        (await AxiosInstance.timesheetClient.timesheetClientControllerFindOneByToken(
          token!,
        )) as unknown as Timesheet;
      if (result.status === 'Rejected') {
        result.status = 'Generated';
      }
      setTimings((result.timings ?? []) as AddTimingsDto[]);
      setTimesheet(result);
      setIsLoading(false);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const onClickSave = async () => {
    try {
      await AxiosInstance.timesheetClient.timesheetClientControllerAddTimingsByCandidateToken(
        token!,
        {
          timings,
        },
      );
      toast.success('Timesheet sent successfully');
      getTimesheetDetails();
    } catch (error) {
      toast.error('Something went wrong. Please verify the fields');
    }
  };

  const onClickRemoveDay = (index: number, dayName: string) => {
    setTimings((prev) => {
      const result = prev.filter((el, index2) => index2 !== index);
      return [...result];
    });
    toast.warning(`${dayName} has been deleted`);
  };

  useEffect(() => {
    getTimesheetDetails();
  }, []);

  if (isLoading) {
    return <></>;
  }

  if (!timesheet) {
    return <>Timesheet not found</>;
  }

  return (
    <>
      <TimesheetContainer className="container" id="container">
        <div className="logo-container">
          <AppIcon />
        </div>
        {timesheet.approval === 'Rejected' && (
          <div className="title">Timesheet rejected</div>
        )}
        <div className="title">
          {timesheet.status === 'Generated'
            ? 'Please fill in your Agency Locum Weekly timesheet'
            : 'Timesheet is already submitted'}
        </div>
        <div className="title">Timesheet</div>
        <FieldText
          label="Placement"
          value={
            timesheet?.placement.job.client.clientName +
            ' - ' +
            timesheet?.placement.job.specialty
          }
          disabled
        />
        <div className="subtitle">Personal Details</div>
        <div className="personal-info-container">
          <div className="personal-name">
            {timesheet?.placement?.candidate?.title}{' '}
            {timesheet?.placement?.candidate?.firstName}{' '}
            {timesheet?.placement?.candidate?.lastName}
          </div>
          <div className="row">
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Specialty</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.candidate?.designation}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Department</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.candidate?.department}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Week Commencing</div>
              <div className="info-item-data col-7">
                {formatDate(timesheet?.placement?.availableFrom ?? '')}
              </div>
            </div>{' '}
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Team</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.job.specialty}
              </div>
            </div>{' '}
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Email Address</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.candidate.email}
              </div>
            </div>
            <div className="col-md-6 row">
              <div className="info-item-title col-5">Grade</div>
              <div className="info-item-data col-7">
                {timesheet?.placement?.job?.grade}
              </div>
            </div>
          </div>
        </div>
        <div className="subtitle">Update Timesheet below</div>
        <div className="text">
          Breaks Must be recorded in the same detail as the Assignment
          confirmation
        </div>
        <div className="accordion" id="accordionPanelsStayOpenExample">
          {timings?.map((el, index) => {
            const dayName = `${new Date(el.date).toLocaleDateString('en-US', {
              weekday: 'long',
            })}- ${formatDate(el.date)}`;
            return (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#panelsStayOpen-${index}`}
                    aria-expanded="true"
                    aria-controls={`panelsStayOpen-${index}`}
                  >
                    {dayName}
                  </button>
                </h2>
                <div
                  id={`panelsStayOpen-${index}`}
                  className="accordion-collapse collapse show"
                  aria-labelledby="panelsStayOpen-headingOne"
                >
                  <div className="accordion-body">
                    <div className="info-card-content row mb-5">
                      <Table>
                        <thead>
                          <tr>
                            <th>Working Day</th>
                            <th>Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Start time</td>
                            <td>
                              <FieldText
                                type="time"
                                onChange={(e) =>
                                  setTimings((prev) => {
                                    prev[index].startTime = e.target.value;
                                    return [...prev];
                                  })
                                }
                                value={timings[index].startTime}
                                disabled={timesheet.status !== 'Generated'}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Finish time</td>
                            <td>
                              <FieldText
                                type="time"
                                onChange={(e) =>
                                  setTimings((prev) => {
                                    prev[index].endTime = e.target.value;
                                    return [...prev];
                                  })
                                }
                                value={timings[index].endTime}
                                disabled={timesheet.status !== 'Generated'}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>On call hours</td>
                            <td>
                              <Select
                                placeholder="Select on call hours"
                                options={[
                                  {
                                    label: 'No on call hours',
                                    value: 0,
                                  },
                                  ...durations,
                                ]}
                                onChange={(value) => {
                                  setTimings((prev) => {
                                    prev[index].callHours = Number(value ?? 0);
                                    return [...prev];
                                  });
                                }}
                                value={timings[index].callHours}
                                disabled={timesheet.status !== 'Generated'}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>Breaks </td>
                            <td>
                              <Select
                                placeholder="Select breaks duration"
                                options={[
                                  {
                                    label: 'No breaks',
                                    value: 0,
                                  },
                                  ...durations,
                                ]}
                                onChange={(value) => {
                                  setTimings((prev) => {
                                    prev[index].breakTime = Number(value ?? 0);
                                    return [...prev];
                                  });
                                }}
                                value={timings[index].breakTime}
                                disabled={timesheet.status !== 'Generated'}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      <div className="d-flex justify-content-end">
                        {timesheet.status === 'Generated' && (
                          <Button
                            type="danger"
                            onClick={() => onClickRemoveDay(index, dayName)}
                          >
                            <DeleteIcon />
                            Remove this day
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </TimesheetContainer>

      <TimesheetActionContainer className="container">
        <div className="left-container">
          Total Working Time : <label>{totalOfWorkingHours}</label>
        </div>
        {timesheet.status === 'Generated' && (
          <div className="right-container" onClick={onClickSave}>
            <Button type="primary">Save</Button>
          </div>
        )}
      </TimesheetActionContainer>
    </>
  );
};

export default TimesheetCandidatePage;
