import React, { useEffect, useState } from 'react';
import { SettingsContainer } from './settings.style';
import {
  CampaignTemplates,
  CardContainer,
  PayrollSetting,
  SubTitlePage,
  TimesheetSetting,
  TitlePage,
} from '../../components';
import { InfoIcon } from '../../icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CampaignsList,
  CompanyDetails,
  RecruitersList,
} from '../../components';

enum ESettings {
  TEAM_ACCESS = 'Team Access',
  CAMPAIGNS = 'Campaigns',
  CAMPAIGN_TEMPLATE = 'Campaign Templates',
  COMPANY = 'Company',
  PAYROLL_SELECTION = 'Payroll Selection',
  TIMESHEET = 'Timesheet',
}

const tabs = [
  ESettings.TEAM_ACCESS,
  ESettings.CAMPAIGNS,
  ESettings.CAMPAIGN_TEMPLATE,
  ESettings.COMPANY,
  ESettings.PAYROLL_SELECTION,
  ESettings.TIMESHEET,
];

export const SettingsPage = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState<ESettings>(
    ESettings.TEAM_ACCESS,
  );

  useEffect(() => {
    if (!type) {
      setSelectedTab(ESettings.TEAM_ACCESS);
      return;
    }

    setSelectedTab(tabs.find((el) => el === type) ?? ESettings.TEAM_ACCESS);
  }, [location]);

  return (
    <>
      <SettingsContainer>
        <div className="header-page">
          <TitlePage>Settings</TitlePage>
          <SubTitlePage>Manage your Settings</SubTitlePage>
        </div>

        <div className="content-page">
          <CardContainer className="left-container">
            <div className="setting-list-items">
              {tabs.map((el) => (
                <div
                  className={`setting-item ${selectedTab === el && 'active'}`}
                  onClick={() => navigate('/settings/' + el)}
                >
                  <InfoIcon />
                  {el}
                </div>
              ))}
            </div>
          </CardContainer>
          {selectedTab === ESettings.TEAM_ACCESS && <RecruitersList />}
          {selectedTab === ESettings.CAMPAIGNS && <CampaignsList />}
          {selectedTab === ESettings.CAMPAIGN_TEMPLATE && <CampaignTemplates />}
          {selectedTab === ESettings.PAYROLL_SELECTION && <PayrollSetting />}
          {selectedTab === ESettings.TIMESHEET && <TimesheetSetting />}
          {selectedTab === ESettings.COMPANY && <CompanyDetails />}
        </div>
      </SettingsContainer>
    </>
  );
};
