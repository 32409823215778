import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const CandidateDetailsContainer = styled.div`
  .candidate-header {
    display: flex;
    align-items: center;
    gap: 5px;
    .back-button {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:hover {
        background-color: #f1f2f4;
      }
      svg {
        path {
          fill: #111827;
        }
      }
    }
  }

  .task-container {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .action-item {
    .disabled {
      cursor: not-allowed;
      background-color: gray;
    }
  }

  .notes-container {
    white-space: break-spaces;
  }

  @media (max-width: ${breakpoint.mobile}px) {
    .candidate-header {
      .back-button {
        width: 20px;
        height: 20px;
      }
    }
  }
`;
