import styled from 'styled-components';
import { breakpoint } from '../../../constants';

export const MessageListContainer = styled.div`
  width: 350px;
  max-width: 350px;
  border-right: 1px solid #f1f2f4;
  display: flex;
  flex-direction: column;
  .top-container {
    padding: 24px 24px 0 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-bottom: 1px solid #f1f2f4;
  }
  .bottom-container.inbox-list {
    display: flex;
    flex-direction: column;
    padding: 10px;
    overflow-y: auto;
    .inbox-item {
      border-radius: 16px;
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 20px;
      cursor: pointer;
      &.active,
      &:hover {
        background-color: #fafafa;
      }
      &.active {
        font-weight: bold;
      }
      .profile-image {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .inbox-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        flex: 1;
        .inbox-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .inbox-name {
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            width: 160px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .inbox-time {
            color: #a0aec0;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }
        }
        .inbox-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .last-message {
            font-size: 12px;
            width: 160px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .number-unread-messages {
            padding: 2px 10px;
            color: white;
            background-color: #e03137;
            border-radius: 100px;
            font-size: 12px;
          }
        }
      }
    }
  }

  @media (max-width: ${breakpoint.mobile}px) {
    width: unset;
    .top-container {
      display: none;
    }
    .bottom-container.inbox-list {
      display: flex;
      flex-direction: column;
      padding: 10px;
      overflow-y: auto;
      .inbox-item {
        padding: 0;
        gap: 20px;
        &.active {
          font-weight: bold;
        }
        .profile-image {
        }
        .inbox-container {
          display: none;
        }
      }
    }
  }
`;
