// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
::before,
::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-color: #e6e7e7;
}

a {
  text-decoration: none;
}

.accordion-button {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  color: #212529 !important;
  box-shadow: unset !important;
  background-color: unset !important;
}

.accordion-button::after {
  background-image: unset !important;
}

input[type='checkbox'] {
  width: 16px;
  height: 16px;
  cursor: pointer;
  accent-color: #27a376;
}

.notification-title {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
}

.notification-description {
  font-weight: 400;
  color: #687588;
  font-size: 13px;
}

@media (max-width: 576px) {
  input[type='checkbox'] {
    width: 12px;
    height: 12px;
  }

  .notification-title {
    font-size: 12px;
  }

  .notification-description {
    font-size: 9px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/App.css"],"names":[],"mappings":"AACA;;;EAGE,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iDAAiD;EACjD,yBAAyB;EACzB,4BAA4B;EAC5B,kCAAkC;AACpC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,cAAc;EAChB;AACF","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');\n*,\n::before,\n::after {\n  box-sizing: border-box;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  background-color: #e6e7e7;\n}\n\na {\n  text-decoration: none;\n}\n\n.accordion-button {\n  border: 1px solid rgba(0, 0, 0, 0.125) !important;\n  color: #212529 !important;\n  box-shadow: unset !important;\n  background-color: unset !important;\n}\n\n.accordion-button::after {\n  background-image: unset !important;\n}\n\ninput[type='checkbox'] {\n  width: 16px;\n  height: 16px;\n  cursor: pointer;\n  accent-color: #27a376;\n}\n\n.notification-title {\n  font-size: 16px;\n  font-weight: 600;\n  color: #111827;\n}\n\n.notification-description {\n  font-weight: 400;\n  color: #687588;\n  font-size: 13px;\n}\n\n@media (max-width: 576px) {\n  input[type='checkbox'] {\n    width: 12px;\n    height: 12px;\n  }\n\n  .notification-title {\n    font-size: 12px;\n  }\n\n  .notification-description {\n    font-size: 9px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
