import React, { useEffect, useState } from 'react';
import { TitleCard, Button, Textarea } from '../../ui';
import { Candidate } from '../../../backend/careo-api';
import { UpdateNoteCandidateFormContainer } from './update-note-candidate-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';

type UpdateNoteCandidateFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  candidate: Candidate;
};

export const UpdateNoteCandidateForm = ({
  onCancel,
  onSuccess,
  candidate,
}: UpdateNoteCandidateFormProps) => {
  const [newNote, setNewNote] = useState('');

  const onClickSubmit = () => {
    AxiosInstance.candidates
      .candidatesControllerUpdateNoteCandidate(candidate._id, {
        notes: newNote,
      })
      .then(() => {
        toast.success('Candidate Note updated successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    setNewNote(candidate.notes ?? '');
  }, [candidate]);

  return (
    <UpdateNoteCandidateFormContainer>
      <TitleCard>Notes Info</TitleCard>
      <Textarea
        placeholder="Enter here ..."
        label=""
        required
        onChange={(e) => setNewNote(e.target.value)}
        value={newNote}
      />
      <div className="form-actions">
        <Button onClick={() => onCancel()}>Cancel</Button>
        <Button type="primary" onClick={onClickSubmit}>
          Update Notes
        </Button>
      </div>
    </UpdateNoteCandidateFormContainer>
  );
};
