import React, { useEffect, useState } from 'react';
import { Select, TitleCard, FieldText, Button, FieldFile } from '../../ui';
import {
  countriesWithRegions,
  regionsWithCounties,
  titles,
} from '../../../constants';
import { useForm } from 'react-hook-form';
import { CreatePayrollDto, Recruiter } from '../../../backend/careo-api';
import { NewPayrollFormContainer } from './new-payroll-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import PayrollSchema from '../../../utils/validators/payrolls.validator';
import { handleFormsServerErrors } from '../../../utils/utils';

export const NewPayrollForm = ({
  onCancel,
  onSuccess,
}: {
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const [recruitersList, setRecruitersList] = useState<Recruiter[]>([]);

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    clearErrors,
    setError,
    setFocus,
  } = useForm<CreatePayrollDto>({
    resolver: yupResolver(PayrollSchema as any),
  });
  const formValues = watch();

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.payrolls
      .payrollsControllerCreate({
        ...values,
        employmentType: '-',
        standardWorkingHoursPerDay: '-',
      })
      .then(() => {
        toast.success('Payroll provider added successfully');
        onSuccess();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);

        toast.error(error.message);
      });
  };

  const getRecruiters = async () => {
    AxiosInstance.recruiters
      .recruitersControllerGetAllRecruiters()
      .then((response) => {
        setRecruitersList(response.data as unknown as Recruiter[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    getRecruiters();
  }, []);

  return (
    <NewPayrollFormContainer>
      <Select
        placeholder="Select recruiter"
        label="Recruiter"
        required
        options={[
          ...recruitersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          })),
        ]}
        register={register('recruiterId')}
        error={errors.recruiterId}
      />
      <TitleCard>Client Details</TitleCard>
      <FieldText
        placeholder="Enter here ..."
        label="Payroll Provider Name"
        required
        register={register('payrollProviderName')}
        error={errors.payrollProviderName}
      />
      <FieldText
        placeholder="Enter here ..."
        required
        type="number"
        label="Vat number"
        register={register('vatNumber')}
        error={errors.vatNumber}
      />
      <FieldFile placeholder="Enter here ..." label="Logo" />
      <TitleCard>Primary Contact</TitleCard>
      <div className="row">
        <div className="col-md-2">
          <Select
            placeholder="Title"
            label="Title"
            required
            options={titles.map((el) => ({ label: el, value: el }))}
            register={register('title')}
            error={errors.title}
          />
        </div>
        <div className="col-md-5">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('leadContactFirstName')}
            error={errors.leadContactFirstName}
          />
        </div>
        <div className="col-md-5">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('leadContactLastName')}
            error={errors.leadContactLastName}
          />
        </div>
      </div>
      <FieldText
        placeholder="Enter here ..."
        label="Job Title"
        required
        register={register('leadContactJobTitle')}
        error={errors.leadContactJobTitle}
      />
      <FieldText
        placeholder="Enter here ..."
        label="Email Address"
        required
        register={register('leadContactEmail')}
        error={errors.leadContactEmail}
      />
      <div className="row">
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.leadContactMobilePhoneNumber}
            placeholder="+442071234567"
            label="Mobile Phone Number"
            required
            register={register('leadContactMobilePhoneNumber')}
            error={errors.leadContactMobilePhoneNumber}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            type="phoneNumber"
            setValue={setValue}
            value={formValues.leadContactWorkPhoneNumber}
            placeholder="+442071234567"
            label="Work Phone Number"
            required
            register={register('leadContactWorkPhoneNumber')}
            error={errors.leadContactWorkPhoneNumber}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Website"
            required
            register={register('website')}
            error={errors.website}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Fax Number"
            required
            register={register('leadContactFax')}
            error={errors.leadContactFax}
          />
        </div>
      </div>

      <TitleCard>Documents</TitleCard>
      <FieldFile placeholder="Enter here ..." label="Documents" />
      {/* address form */}
      <>
        <TitleCard>Address</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          required
          label="Street Address"
          register={register('address.street', { required: true })}
          error={errors.address?.street}
        />
        <FieldText
          placeholder="Enter here ..."
          required
          label="City"
          register={register('address.city', { required: true })}
          error={errors.address?.city}
        />
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select country"
              required
              label="Country"
              options={countriesWithRegions.map((el) => ({
                label: el.country,
                value: el.country,
              }))}
              register={register('address.country', { required: true })}
              error={errors.address?.country}
            />
          </div>
          <div className="col-md-6">
            <Select
              placeholder="Select Region"
              label="Region"
              required
              options={
                countriesWithRegions
                  .find((el) => el.country === formValues.address?.country)
                  ?.regions?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.region', { required: 'true' })}
              error={errors.address?.region}
              disabled={!formValues.address?.country}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Select
              placeholder="Select County"
              label="County"
              required
              options={
                regionsWithCounties
                  .find((el) => el.region === formValues.address?.region)
                  ?.counties?.map((el) => ({
                    label: el,
                    value: el,
                  })) ?? []
              }
              register={register('address.county', { required: 'true' })}
              error={errors.address?.county}
              disabled={!formValues.address?.region}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Postcode"
              required
              register={register('address.zip', { required: true })}
              error={errors.address?.zip}
            />
          </div>
        </div>
      </>
      <TitleCard>Bank details</TitleCard>
      <FieldText
        placeholder="Enter here ..."
        required
        label="Bank Name"
        register={register('bank.name')}
        error={errors.bank?.name}
      />
      <FieldText
        placeholder="Enter here ..."
        required
        label="Bank Account Name"
        register={register('bank.accountName')}
        error={errors.bank?.accountName}
      />
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            required
            label="Sort Code"
            register={register('bank.sortNumber')}
            error={errors.bank?.sortNumber}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            required
            label="Account Number"
            register={register('bank.accountNumber')}
            error={errors.bank?.accountNumber}
          />
        </div>
      </div>
      <div className="form-actions">
        <Button onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </NewPayrollFormContainer>
  );
};
