import React, { Dispatch, SetStateAction } from 'react';
import { Select, TitleCard, FieldText, Button, FieldFile } from '../../ui';
import { titles } from '../../../constants';
import { useForm } from 'react-hook-form';
import { CreateDepartmentClientDto } from '../../../backend/careo-api';
import { NewDepartmentFormContainer } from './new-department-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

type NewDepartmentFormProps = {
  getClientDetails: any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const NewDepartmentForm = ({
  setIsOpen,
  getClientDetails,
}: NewDepartmentFormProps) => {
  const { id } = useParams();

  const {
    register,
    getValues,
    formState: { errors },
    watch,
    setValue,
  } = useForm<CreateDepartmentClientDto>();

  const formValues = watch();

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.clients
      .clientsControllerAddDepartment(id!, {
        ...values,
      })
      .then(() => {
        toast.success('Department added successfully');
        getClientDetails();
        onClickClose();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  return (
    <NewDepartmentFormContainer>
      <>
        <TitleCard>Contact</TitleCard>
        <div className="row">
          <div className="col-md-2">
            <Select
              placeholder="Title"
              label="Title"
              required
              options={titles.map((el) => ({ label: el, value: el }))}
              register={register('titleContact')}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="First Name"
              required
              register={register('firstNameContact', { required: true })}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Last Name"
              required
              register={register('lastNameContact', { required: true })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Department"
              required
              register={register('departmentContact', { required: true })}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Team"
              required
              register={register('teamContact', { required: true })}
            />
          </div>
        </div>
        <FieldText
          placeholder="Enter here ..."
          label="Job title"
          required
          register={register('jobTitleContact', { required: true })}
        />
        <FieldText
          placeholder="Enter here ..."
          label="Email"
          required
          register={register('emailContact', { required: true })}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              setValue={setValue}
              value={formValues.phoneNumberContact}
              placeholder="+442071234567"
              label="Mobile Phone Number"
              required
              register={register('phoneNumberContact', { required: true })}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              setValue={setValue}
              value={formValues.workPhoneNumberContact}
              placeholder="+442071234567"
              label="Work Phone Number"
              required
              register={register('workPhoneNumberContact', { required: true })}
            />
          </div>
        </div>
      </>
      <>
        <TitleCard>Documents</TitleCard>
        <FieldFile placeholder="Enter here ..." label="Upload attachment" />
      </>
      <>
        <TitleCard>Account</TitleCard>
        <div className="row">
          <div className="col-md-2">
            <Select
              placeholder="Title"
              label="Title"
              required
              options={titles.map((el) => ({ label: el, value: el }))}
              register={register('titleAccount')}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="First Name"
              required
              register={register('firstNameAccount', { required: true })}
            />
          </div>
          <div className="col-md-5">
            <FieldText
              placeholder="Enter here ..."
              label="Last Name"
              required
              register={register('lastNameAccount', { required: true })}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Department"
              required
              register={register('phoneNumberAccount', { required: true })}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Team"
              required
              register={register('workPhoneNumberAccount', { required: true })}
            />
          </div>
        </div>
        <FieldText
          placeholder="Enter here ..."
          label="Job title"
          required
          register={register('jobTitleAccount', { required: true })}
        />
        <FieldText
          placeholder="Enter here ..."
          label="Email"
          required
          register={register('emailAccount', { required: true })}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              setValue={setValue}
              value={formValues.phoneNumberAccount}
              placeholder="+442071234567"
              label="Mobile Phone Number"
              required
              register={register('phoneNumberAccount', { required: true })}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              type="phoneNumber"
              setValue={setValue}
              value={formValues.workPhoneNumberAccount}
              placeholder="+442071234567"
              label="Work Phone Number"
              required
              register={register('workPhoneNumberAccount', { required: true })}
            />
          </div>
        </div>
      </>
      <>
        <TitleCard>Bank details</TitleCard>
        <FieldText
          placeholder="Enter here ..."
          label="Bank Name"
          register={register('bank.name')}
        />
        <FieldText
          placeholder="Enter here ..."
          label="Bank Account Name"
          register={register('bank.accountName')}
        />
        <div className="row">
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Sort Code"
              register={register('bank.sortNumber')}
            />
          </div>
          <div className="col-md-6">
            <FieldText
              placeholder="Enter here ..."
              label="Account Number"
              register={register('bank.accountNumber')}
            />
          </div>
        </div>
      </>
      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={onClickSubmit}>
          Create
        </Button>
      </div>
    </NewDepartmentFormContainer>
  );
};
