import { useRef, useState, useMemo, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Compliance,
  Candidate,
  Notification,
  HostedFile,
  ExtendedCandidate,
} from '../../backend/careo-api';
import {
  TitlePage,
  CardContainer,
  UserIcon,
  ATag,
  ComplianceEdit,
  TitleCard,
  ComplianceList,
  Button,
  ProgressBar,
  DragDropUpload,
} from '../../components';
import { CandidateDetailsGeneral } from '../../components/candidate/candidate-details';
import { ComplianceStatus } from '../../components/compliance/compliance-status/compliance-status.component';
import {
  CandidateComplianceDetailsContainer,
  ComplianceContainer,
} from '../../components/compliance/compliance/compliance.style';
import { DocumentsListComponent } from '../../components/document';
import { SideModal } from '../../components/modals';
import { NewNotificationsList } from '../../components/notification';
import { NewNotificationsListContainer } from '../../components/notification/notifications-list/notifications-list.style';
import {
  LeftArrowIcon,
  CallIcon,
  MessageIcon,
  NewSmallNotificationRedIcon,
} from '../../icons';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  uploadCandidateFile,
  getTimeAgo,
} from '../../utils';
import {
  TNotificationUI,
  getNotificationData,
} from '../../utils/notifcations.utils';

export enum ECandidateDetailsTabs {
  GENERAL = 'General',
  DOCUMENTS = 'Documents',
  COMPLIANCE = 'Compliance',
}

export const CandidateComplianceDetailsPage = () => {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const location = useLocation();

  const tabs = [
    {
      name: ECandidateDetailsTabs.GENERAL,
      url: `/candidates/${id}`,
    },
    {
      name: ECandidateDetailsTabs.DOCUMENTS,
      url: `/candidates/${id}/documents`,
    },
    {
      name: ECandidateDetailsTabs.COMPLIANCE,
      url: `/candidates/${id}/compliance`,
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<{
    url: string;
    name: string;
  } | null>(null);
  const [selectedTabCompliance, setSelectedTabCompliance] =
    useState<Compliance | null>(null);

  const [candidate, setCandidate] = useState<ExtendedCandidate | null>(null);
  const [candidateDocuments, setCandidateDocuments] = useState<HostedFile[]>(
    [],
  );
  const [compliancesList, setCompliancesList] = useState<Compliance[]>([]);
  const [notifications, setNotifications] = useState<TNotificationUI[]>([]);

  const onClickNotificationItem = async (notificationUI: TNotificationUI) => {
    navigate(notificationUI.url);
    await AxiosInstance.notifications
      .notificationsControllerViewNotification(notificationUI._id)
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const onClickDelete = (documentId: string) => {
    AxiosInstance.files
      .filesControllerDeleteFile(documentId)
      .then(() => {
        toast.success('File deleted successfully');
        getComplianceList();
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Failed to delete file');
      });
  };

  const handleFileChange = async (file: File) => {
    await uploadCandidateFile(id!, file)
      .then(() => {
        toast.success('Document uploaded successfully');
        getCandidateDocuments();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onClickGoBack = () => {
    navigate('/candidates');
  };

  const onClickTab = (url: string) => {
    navigate(url);
  };

  const getComplianceList = async () => {
    await AxiosInstance.compliances
      .compliancesControllerGetAllCompliances({
        candidateId: id,
      })
      .then((response) => {
        setCompliancesList(response.data as unknown as Compliance[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCandidateDetails = () => {
    AxiosInstance.candidates
      .candidatesControllerGetCandidate(id!, { analytics: true })
      .then((response) => {
        setCandidate(response as any);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getCandidateDocuments = () => {
    AxiosInstance.files
      .filesControllerFindCandidateDocs(id!)
      .then((response) => {
        setCandidateDocuments(response as any);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const getNotifications = async () => {
    await AxiosInstance.notifications
      .notificationsControllerFindAll()
      .then((response) => {
        const notificationsResponse =
          response.data as unknown as Notification[];
        const result = notificationsResponse
          .map((el) => getNotificationData(el))
          .filter((el) => el?.title) as TNotificationUI[];
        setNotifications(result);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  useEffect(() => {
    if (!type) {
      setSelectedTabCompliance(null);
    }
    const selectedCompliance =
      compliancesList.find((com) => com.taskName === type) ?? null;

    setSelectedTabCompliance(selectedCompliance);
  }, [location, compliancesList]);

  useEffect(() => {
    let tab = tabs.find((el) => el.url === location.pathname) ?? null;
    if (!tab && type) {
      const complianceTab = `/candidates/${id}/compliance/${type}`;
      if (decodeURIComponent(location.pathname) === complianceTab) {
        tab = {
          name: ECandidateDetailsTabs.COMPLIANCE,
          url: `/candidates/${id}/compliance`,
        };
      }
    }

    setSelectedTab(tab);
  }, [location]);

  useEffect(() => {
    getCandidateDetails();
    getCandidateDocuments();
    getComplianceList();
    getNotifications();
  }, []);

  if (!candidate) {
    return <></>;
  }

  return (
    <>
      <CandidateComplianceDetailsContainer>
        <div className="candidate-header">
          <div className="back-button" onClick={onClickGoBack}>
            <LeftArrowIcon />
          </div>
          <TitlePage>Detail Candidate</TitlePage>
        </div>
        <CardContainer className="page-summary-container">
          <div className="module-name">
            <UserIcon
              firstName={candidate.firstName}
              lastName={candidate.lastName}
            />
            <div className="module-info">
              <div className="name">
                {candidate.firstName} {candidate.lastName}
              </div>
              <div className="availability">{candidate.address?.city}</div>
            </div>
          </div>
          <div className="module-contact">
            <CallIcon />
            <div>{candidate.phoneNumber}</div>
          </div>
          <div className="module-contact">
            <MessageIcon />
            <div>{candidate.email}</div>
          </div>
          <div className="actions-container">
            <Button
              type="primary"
              onClick={() => navigate(`/messages/candidate/${candidate._id}`)}
            >
              Message
            </Button>
          </div>
          <div className="progress-container"></div>
        </CardContainer>
        <CardContainer className="candidate-information-container">
          <div className="tab-container">
            <div className="tab-items-list">
              {tabs.map((el) => (
                <ATag
                  className={`tab-item ${
                    el.url === selectedTab?.url && 'active'
                  }`}
                  onClick={() => onClickTab(el.url)}
                >
                  {el.name}
                </ATag>
              ))}
            </div>
            {(selectedTab?.name === ECandidateDetailsTabs.GENERAL ||
              selectedTab?.name === ECandidateDetailsTabs.DOCUMENTS) && (
              <div className="tab-content-container">
                {selectedTab?.name === ECandidateDetailsTabs.GENERAL && (
                  <CandidateDetailsGeneral candidate={candidate} />
                )}
                {selectedTab?.name === ECandidateDetailsTabs.DOCUMENTS && (
                  <>
                    <div className="info-card">
                      <div className="info-card-title">Personal Documents</div>
                      <hr />
                      <div className="data-table-container">
                        <DocumentsListComponent
                          documents={candidateDocuments}
                          onClickDelete={onClickDelete}
                          getDocuments={getCandidateDocuments}
                        />
                      </div>
                    </div>
                    <div className="info-card">
                      <DragDropUpload onUpload={handleFileChange} />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </CardContainer>
        {selectedTab?.name === ECandidateDetailsTabs.COMPLIANCE && (
          <>
            {selectedTabCompliance ? (
              <CardContainer className="candidate-information-container">
                <div className="tab-container">
                  <div className="tab-content-container">
                    <ComplianceContainer>
                      <ComplianceEdit
                        selectedTab={selectedTabCompliance}
                        getComplianceList={getComplianceList}
                      />
                    </ComplianceContainer>
                  </div>
                </div>
              </CardContainer>
            ) : (
              <div className="compliance-list-container">
                <CardContainer className="candidate-information-container">
                  <TitleCard>Compliance</TitleCard>
                  <div className="tab-container">
                    <div className="tab-content-container">
                      <ComplianceContainer>
                        <div className="info-card compliance-info-container">
                          <div className="compliance-status">
                            Compliance Status :
                            <ComplianceStatus
                              value={candidate.complianceStatus}
                            />
                          </div>
                          <div className="compliance-progress-bar">
                            <ProgressBar
                              value={candidate.completionPercentage}
                            />
                            {candidate.completionPercentage}%
                          </div>
                        </div>
                        <div className="data-table-container">
                          <ComplianceList
                            compliancesList={compliancesList}
                            getComplianceList={getComplianceList}
                          />
                        </div>
                      </ComplianceContainer>
                    </div>
                  </div>
                </CardContainer>
                <CardContainer className="compliance-updates-container">
                  <TitleCard>Updates</TitleCard>
                  <div className="tab-container">
                    <div className="tab-content-container">
                      <ComplianceContainer>
                        {!!notifications.length ? (
                          <NewNotificationsListContainer>
                            <div className="notification-content-list">
                              {notifications.slice(0, 5).map((el) => {
                                return (
                                  <div
                                    className="notification-item"
                                    onClick={() => onClickNotificationItem(el)}
                                  >
                                    <div className="notification-item-top">
                                      <div className="notification-title">
                                        {el?.title}
                                      </div>
                                      <div className="notification-time">
                                        {getTimeAgo(el.createdDate)}
                                      </div>
                                    </div>
                                    <div className="notification-item-bottom">
                                      <div className="notification-description">
                                        {el?.description}
                                      </div>
                                      {el.isNew && (
                                        <NewSmallNotificationRedIcon />
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <br />
                            <Button
                              onClick={() => setIsModalOpen(true)}
                              type="primary"
                            >
                              Show All
                            </Button>
                          </NewNotificationsListContainer>
                        ) : (
                          <div className="no-items pt-5"> No updates yet</div>
                        )}
                      </ComplianceContainer>
                    </div>
                  </div>
                </CardContainer>
              </div>
            )}
          </>
        )}
      </CandidateComplianceDetailsContainer>
      <SideModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        title="Updates"
      >
        <NewNotificationsList
          setIsOpen={setIsModalOpen}
          notifications={notifications}
          onClickNotificationItem={onClickNotificationItem}
          getNotifications={getNotifications}
        />
      </SideModal>
    </>
  );
};
