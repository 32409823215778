import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { TitleCard, FieldText, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import { CreateRecruiterDto, Recruiter } from '../../../backend/careo-api';
import { UpdateRecruiterFormContainer } from './update-recruiter-form.style';
import { AxiosInstance } from '../../../utils';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  AddRecruiterSchema,
  UpdateRecruiterSchema,
} from '../../../utils/validators/recruiter.validator';

type UpdateRecruiterFormProps = {
  recruiter: Recruiter;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getRecruiters: () => void;
};

export const UpdateRecruiterForm = ({
  recruiter,
  setIsOpen,
  getRecruiters,
}: UpdateRecruiterFormProps) => {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<CreateRecruiterDto>({
    resolver: yupResolver(UpdateRecruiterSchema as any),
  });

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();

    AxiosInstance.recruiters
      .recruitersControllerUpdate(recruiter._id, values)
      .then(() => {
        toast.success('Recruiter updated successfully');
        getRecruiters();
        onClickClose();
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  useEffect(() => {
    reset(recruiter);
  }, []);

  return (
    <UpdateRecruiterFormContainer>
      <FieldText label="Role" required value="Recruiter" disabled />
      <TitleCard>Recruiter Details</TitleCard>
      <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="First Name"
            required
            register={register('firstName', { required: true })}
            error={errors.firstName}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Last Name"
            required
            register={register('lastName', { required: true })}
            error={errors.lastName}
          />
        </div>
      </div>
      <FieldText
        placeholder="Enter here ..."
        label="Email"
        required
        register={register('email', { required: true })}
        error={errors.email}
      />
      <FieldText
        type="phoneNumber"
        placeholder="+442071234567"
        label="Phone Number"
        required
        register={register('phoneNumber', { required: true })}
        error={errors.phoneNumber}
      />
      {/* <div className="row">
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Password"
            required
            register={register('password', { required: true })}
            error={errors.password}
          />
        </div>
        <div className="col-md-6">
          <FieldText
            placeholder="Enter here ..."
            label="Confirm Password"
            required
            register={register('confirmPassword', { required: true })}
            error={errors.confirmPassword}
          />
        </div>
      </div> */}

      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Update
        </Button>
      </div>
    </UpdateRecruiterFormContainer>
  );
};
