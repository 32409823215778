import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Select, TitleCard, Button } from '../../ui';
import { useForm } from 'react-hook-form';
import {
  Application,
  Candidate,
  Job,
  Recruiter,
} from '../../../backend/careo-api';
import { NewPlacementFormContainer } from './new-placement-form.style';
import { AxiosInstance, AxiosInstanceErrorResponse } from '../../../utils';
import { toast } from 'react-toastify';
import { EApplicationStatus } from '../../vacancy/add-candidate-vacancy-form/add-candidate-vacancy-form.component';
import { handleFormsServerErrors } from '../../../utils/utils';

type NewPlacementFormProps = {
  applicationsList: Application[];
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  getPlacements: any;
};

export const NewPlacementForm = ({
  applicationsList,
  setIsOpen,
  getPlacements,
}: NewPlacementFormProps) => {
  const [recruitersList, setRecruitersList] = useState<Recruiter[]>([]);

  const {
    getValues,
    handleSubmit,
    formState: { errors },
    register,
    setError,
    setFocus,
  } = useForm<{ applicationId: string }>();

  const onClickClose = () => {
    setIsOpen(false);
  };

  const onClickSubmit = () => {
    const values = getValues();
    AxiosInstance.applications
      .applicationsControllerUpdateApplicationStatus(
        values.applicationId as any,
        {
          status: EApplicationStatus.placement,
        },
      )
      .then(() => {
        getPlacements();
        toast.success('Placement added successfully');
        onClickClose();
      });
  };

  const getRecruiters = async () => {
    AxiosInstance.recruiters
      .recruitersControllerGetAllRecruiters()
      .then((response) => {
        setRecruitersList(response.data as unknown as Recruiter[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        handleFormsServerErrors(error, setError, setFocus);

        toast.error(error.message);
      });
  };

  useEffect(() => {
    getRecruiters();
  }, []);

  return (
    <NewPlacementFormContainer>
      <Select
        placeholder="Select recruiter"
        required
        label="Recruiter"
        options={[
          ...recruitersList.map((el) => ({
            label: el.firstName + ' ' + el.lastName,
            value: el._id,
          })),
        ]}
      />
      <TitleCard>Placement Details</TitleCard>
      <Select
        placeholder="Select Application"
        label="Application"
        options={[
          ...applicationsList.map((el) => ({
            label:
              (el.job as Job)?.grade +
              ' / ' +
              (el.job as Job)?.specialty +
              ' : ' +
              (el.candidate as Candidate)?.firstName +
              ' ' +
              (el.candidate as Candidate)?.lastName,
            value: el._id,
          })),
        ]}
        register={register('applicationId')}
      />
      <div className="form-actions">
        <Button onClick={onClickClose}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit(onClickSubmit)}>
          Create
        </Button>
      </div>
    </NewPlacementFormContainer>
  );
};
