import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  CardContainer,
  TitlePage,
  MessageContent,
  MessageList,
} from '../../components';
import { MessagesPageContainer } from './messages.style';
import {
  Candidate,
  Client,
  HostedFile,
  WhatsappLogsCandidates,
} from '../../backend/careo-api';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  WebsocketMessagesTypes,
  sendEmailRequest,
  sendWhatsappRequest,
  socket,
} from '../../utils';
import { PagesUrls } from '../../routes/page-urls';
import { isCRMApp } from '../../environment/app.type';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/auth.context';

export enum USER_TYPE {
  CANDIDATE = 'candidate',
  CLIENT = 'client',
}

type MessagesPageProps = {
  type: USER_TYPE;
};

export type TUserMessage = {
  _id: string;
  firstName: string;
  lastName: string;
  online: boolean;
  lastMessage: string;
  lastMessageTime: string;
  unreadMessages: number;
  isReply: boolean;
  email: string;
  whatsappId: string;
};

export interface WhatsappMessageUI
  extends Omit<WhatsappLogsCandidates, 'candidate' | 'sender'> {
  receiverId: string;
  isLoading: boolean;
  sender: 'client' | 'candidate' | 'recruiter';
}

export interface EmailsMessageUi {
  _id: string;
  receiverId: string;
  sender: 'client' | 'candidate' | 'recruiter';
  subject: string;
  text: string;
  hostedFiles: HostedFile[];
  timestamp: string;
  isRead: boolean;
  isReply: boolean;
}

interface SocketMessageEvent {
  user: Candidate | Client;
  userType: USER_TYPE;
  recruiterId: string;
  message?: string;
  file?: HostedFile;
}

interface SocketEmailEvent {
  user: Candidate | Client;
  userType: USER_TYPE;
  recruiterId: string;
  title: string;
  message: string;
  files: HostedFile[];
}

export const MessagesPage = ({ type }: MessagesPageProps) => {
  const { user } = useAuth();

  const { userId } = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(type ?? USER_TYPE.CANDIDATE);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(
    undefined,
  );
  const [candidates, setCandidates] = useState<TUserMessage[]>([]);
  const [clients, setClients] = useState<TUserMessage[]>([]);

  const [listCandidatesMessages, setListCandidatesMessages] = useState<
    WhatsappMessageUI[]
  >([]);

  const [listClientsMessages, setListClientsMessages] = useState<
    WhatsappMessageUI[]
  >([]);

  const [listCandidatesEmails, setListCandidatesEmails] = useState<
    EmailsMessageUi[]
  >([]);

  const [listClientsEmails, setListClientsEmails] = useState<EmailsMessageUi[]>(
    [],
  );

  const getCandidates = async () => {
    try {
      const response = (
        await AxiosInstance.candidates.candidatesControllerGetAllCandidates()
      ).data as unknown as Candidate[];

      const data = response
        .filter((el) => el?.recruiter?._id === user?._id)
        .map((el) => {
          return {
            _id: el._id,
            firstName: el.firstName,
            lastName: el.lastName,
            online: false,
            lastMessage: '...',
            lastMessageTime: new Date().toISOString(),
            unreadMessages: 0,
            isReply: false,
            whatsappId: el.whatsappId,
            email: el.email,
          };
        })
        .sort((a, b) => {
          const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
          const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });

      setCandidates(data);
    } catch (error) {
      console.error('Error fetching candidates:', error);
    }
  };

  const getClients = async () => {
    AxiosInstance.clients
      .clientsControllerGetAllClients()
      .then((response) => {
        const result = response.data as unknown as Client[];

        const data = result
          .map((el) => {
            return {
              _id: el._id,
              firstName: el.firstName,
              lastName: el.lastName,
              online: false,
              lastMessage: '...',
              lastMessageTime: new Date().toISOString(),
              unreadMessages: 0,
              isReply: false,
              whatsappId: el.whatsappId,
              email: el.email,
            };
          })
          .sort((a, b) => {
            const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
            const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          });

        setClients(data);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getMessagesLogs = async (userId: string, platform: string) => {
    try {
      const response =
        selectedTab === USER_TYPE.CANDIDATE
          ? await AxiosInstance.socialPlatforms.socialPlatformsControllerFetchCandidateMessages(
              platform,
              userId,
            )
          : await AxiosInstance.socialPlatforms.socialPlatformsControllerFetchClientsMessages(
              platform,
              userId,
            );
      return response as any;
    } catch (error) {
      return [];
    }
  };

  const sendMessageAndAttachmentWhatsapp = (
    recipientId: string,
    message: string,
    attachment?: File,
  ) => {
    const messageObject: WhatsappMessageUI = {
      receiverId: userId ?? '',
      sender: 'recruiter',
      message,
      hostedFile: attachment ? ({} as any) : undefined,
      timestamp: new Date(),
      isLoading: attachment ? true : false,
      _id: new Date().toISOString(),
    };

    let indexArray: number = 0;
    selectedTab === USER_TYPE.CANDIDATE
      ? setListCandidatesMessages((prev) => {
          const result = [...prev, messageObject];
          indexArray = result.length - 1;
          return result;
        })
      : setListClientsMessages((prev) => {
          const result = [...prev, messageObject];
          indexArray = result.length - 1;
          return result;
        });

    sendWhatsappRequest(
      recipientId,
      selectedTab === USER_TYPE.CANDIDATE ? 'candidate' : 'client',
      message,
      attachment,
    ).then((response: { message: string; file?: HostedFile }) => {
      selectedTab === USER_TYPE.CANDIDATE
        ? setListCandidatesMessages((prev) => {
            const item = prev[indexArray];
            prev[indexArray] = {
              ...item,
              hostedFile: response.file ?? undefined,
              isLoading: false,
            };
            return [...prev];
          })
        : setListClientsMessages((prev) => {
            const item = prev[indexArray];
            prev[indexArray] = {
              ...item,
              hostedFile: response.file ?? undefined,
              isLoading: false,
            };
            return [...prev];
          });
    });
  };

  const sendEmail = (
    subject: string,
    text: string,
    recipient: string,
    files: File[],
  ) => {
    let recipientType: 'candidate' | 'client' = 'candidate';
    const newObject: EmailsMessageUi = {
      _id: new Date().toUTCString(),
      subject,
      text,
      timestamp: new Date().toUTCString(),
      isReply: false,
      receiverId: recipient,
      sender: 'recruiter',
      hostedFiles: [],
      isRead: false,
    };

    if (selectedTab === USER_TYPE.CANDIDATE) {
      recipientType = 'candidate';
      setListCandidatesEmails([...listCandidatesEmails, newObject]);
    } else {
      recipientType = 'client';
      setListClientsEmails([...listClientsEmails, newObject]);
    }

    sendEmailRequest(recipient, recipientType, subject, text, files);
  };

  const onClickSelectUserConversation = (userId: string) => {
    navigate(`${PagesUrls.MESSAGES.Index}/${selectedTab}/${userId}`);
  };

  useEffect(() => {
    setSelectedTab(type);
    setSelectedUserId(userId ?? undefined);
  }, [userId, type]);

  useEffect(() => {
    if (selectedUserId) {
      socket?.emit('select', selectedUserId);

      getMessagesLogs(selectedUserId, 'whatsapp')
        .then((messages) => {
          selectedTab === USER_TYPE.CANDIDATE
            ? setListCandidatesMessages(messages ?? [])
            : setListClientsMessages(messages ?? []);
        })
        .catch(() => {
          selectedTab === USER_TYPE.CANDIDATE
            ? setListCandidatesMessages([])
            : setListClientsMessages([]);
        });

      getMessagesLogs(selectedUserId, 'outlook')
        .then((emails: EmailsMessageUi[]) => {
          const result =
            emails.map((e) => {
              return {
                ...e,
                isReply: e.sender !== 'recruiter',
              };
            }) ?? [];
          selectedTab === USER_TYPE.CANDIDATE
            ? setListCandidatesEmails(result)
            : setListClientsEmails(result);
        })
        .catch(() => {
          selectedTab === USER_TYPE.CANDIDATE
            ? setListCandidatesEmails([])
            : setListClientsEmails([]);
        });
    }
  }, [selectedUserId]);

  useEffect(() => {
    const messageHandler = (messageData: SocketMessageEvent) => {
      const { user, message, userType, file } = messageData;
      if (selectedUserId !== user._id) return;
      const messageObject: WhatsappMessageUI = {
        _id: new Date().toISOString(),
        receiverId: userId ?? '',
        sender: userType,
        message: message ?? '',
        hostedFile: file ? ({} as any) : undefined,
        timestamp: new Date(),
        isLoading: false,
      };

      selectedTab === USER_TYPE.CANDIDATE
        ? setListCandidatesMessages((prev) => [...prev, messageObject])
        : setListClientsMessages((prev) => [...prev, messageObject]);
    };

    const emailHandler = (emailData: SocketEmailEvent) => {
      const { user: receiverUser, message, title, userType, files } = emailData;
      if (selectedUserId !== receiverUser._id) return;
      const newObject: EmailsMessageUi = {
        _id: new Date().toUTCString(),
        subject: title,
        text: message,
        timestamp: new Date().toUTCString(),
        isReply: false,
        receiverId: userId ?? '',
        sender: userType,
        hostedFiles: files ?? [],
        isRead: false,
      };
      selectedTab === USER_TYPE.CANDIDATE
        ? setListCandidatesEmails((prev) => [...prev, newObject])
        : setListClientsEmails((prev) => [...prev, newObject]);
    };

    socket.on(WebsocketMessagesTypes.MESSAGE, messageHandler);
    socket.on(WebsocketMessagesTypes.EMAIL, emailHandler);

    return () => {
      socket.off(WebsocketMessagesTypes.MESSAGE, messageHandler);
      socket.off(WebsocketMessagesTypes.EMAIL, emailHandler);
    };
  }, [socket, selectedUserId]);

  useEffect(() => {
    if (socket) {
      getCandidates();
      if (isCRMApp) {
        getClients();
      }
    }
  }, [socket]);

  return (
    <MessagesPageContainer>
      <div className="messages-header">
        <TitlePage>Messages</TitlePage>
        <div className="filter-container">
          <div
            className={`filter-item ${
              selectedTab === USER_TYPE.CANDIDATE && 'active'
            }`}
            onClick={() => {
              if (type !== USER_TYPE.CANDIDATE) {
                navigate(PagesUrls.MESSAGES.Candidate);
              }
            }}
          >
            Candidates
          </div>
          {isCRMApp && (
            <div
              className={`filter-item ${
                selectedTab === USER_TYPE.CLIENT && 'active'
              }`}
              onClick={() => {
                if (type !== USER_TYPE.CLIENT) {
                  navigate(PagesUrls.MESSAGES.Client);
                }
              }}
            >
              Clients
            </div>
          )}
        </div>
        <div></div>
      </div>
      <CardContainer className="messages-container">
        <MessageList
          users={selectedTab === USER_TYPE.CANDIDATE ? candidates : clients}
          selectedUserId={selectedUserId}
          onUserSelected={onClickSelectUserConversation}
        />
        <MessageContent
          receiverType={selectedTab}
          selectedUser={(selectedTab === USER_TYPE.CANDIDATE
            ? candidates
            : clients
          ).find((c) => c._id === selectedUserId)}
          listEmails={
            selectedTab === USER_TYPE.CANDIDATE
              ? listCandidatesEmails
              : listClientsEmails
          }
          listMessages={
            selectedTab === USER_TYPE.CANDIDATE
              ? listCandidatesMessages ?? []
              : listClientsMessages ?? []
          }
          sendEmail={sendEmail}
          sendMessageAndAttachmentWhatsapp={sendMessageAndAttachmentWhatsapp}
        />
      </CardContainer>
    </MessagesPageContainer>
  );
};
