import React, { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  Button,
  CardContainer,
  Checkbox,
  NewCandidateForm,
  ProgressBar,
  SearchInput,
  SubTitlePage,
  TabFilter,
  Table,
  TitlePage,
  UpdateCandidateForm,
  UserIcon,
} from '../../../components';
import {
  DeleteIcon,
  DotsIcon,
  DownloadIcon,
  EditIcon,
  EyeIcon,
  LeftArrowIcon,
  PlusIcon,
  RightArrowIcon,
  SortIcon,
} from '../../../icons';
import {
  TUIType,
  getCountiesOfSelectedRegions,
  getGradesOfSelectedLevel,
  jobLevelWithGrades,
  regionsWithCounties,
} from '../../../constants';
import {
  AxiosInstance,
  AxiosInstanceErrorResponse,
  ERoute,
  TFilterCandidate,
  completionOptions,
  complianceStatusOptions,
  filterCandidates,
  getItemsOfPage,
  getNumberOfPages,
  onSelectSort,
  sortCandidates,
} from '../../../utils';
import { SideModal } from '../../../components/modals';
import { useNavigate } from 'react-router-dom';
import {
  ExtendedCandidate,
  Payroll,
  Recruiter,
} from '../../../backend/careo-api';
import { toast } from 'react-toastify';
import { isCRMApp } from '../../../environment/app.type';
import { ConfirmModal } from '../../../components/modals/confirm-modal/confirm-modal.component';
import { HeaderPageContainer } from '../../../components/header/header.style';
import { NewSharedCandidatesList } from '../../../components/shared-candidates';
import { MultipleSelect } from '../../../components/ui/inputs/select/multiple-select.component';
import { useAuth } from '../../../contexts/auth.context';
import { ComplianceStatus } from '../../compliance/compliance-status/compliance-status.component';

const numberOfItems = 8;

export const ComplianceCandidatesList = () => {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [candidates, setCandidates] = useState<ExtendedCandidate[]>([]);
  const [candidatesList, setCandidatesList] = useState<ExtendedCandidate[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isModalCreateListOpen, setIsModalCreateListOpen] = useState(false);
  const [isModalAddCandidateOpen, setIsModalAddCandidateOpen] = useState(false);
  const [isModalUpdateCandidateOpen, setIsModalUpdateCandidateOpen] =
    useState<ExtendedCandidate>();
  const [payrollsList, setPayrollsList] = useState<Payroll[]>([]);
  const [recruitersList, setRecruitersList] = useState<Recruiter[]>([]);
  const [recordToDelete, setRecordToDelete] = useState<ExtendedCandidate>();

  const [filter, setFilter] = useState<TFilterCandidate>({
    search: '',
    regions: [],
    counties: [],
    departments: [],
    levels: [],
    grades: [],
    status: [],
    completions: [],
  });

  const [sort, setSort] = useState<{ key: string; value: '+' | '-' | '' }>({
    key: '',
    value: '',
  });

  const [designationOptions, setDesignationOptions] = useState<string[]>([]);
  const [selectedCandidatesIds, setSelectedCandidatesIds] = useState<string[]>(
    [],
  );

  const onClickCheckboxAll = (value: boolean) => {
    setSelectedCandidatesIds((prev) => {
      if (value) {
        return [...candidatesList.map((el) => el._id)];
      } else return [];
    });
  };

  const onClickCheckbox = (id: string, value: boolean) => {
    setSelectedCandidatesIds((prev) => {
      if (value) {
        prev.push(id);
      } else {
        prev = prev.filter((el) => el !== id);
      }
      return [...prev];
    });
  };

  const navigatePagination = (newPage: number) => {
    if (newPage <= 0 || newPage > totalPages) {
      return;
    }
    setCurrentPage(newPage);
  };

  const onClickEdit = (candidate: ExtendedCandidate) => {
    setIsModalUpdateCandidateOpen(candidate);
  };

  const onClickCreate = () => {
    setIsModalAddCandidateOpen(true);
  };

  const onClickCreateList = () => {
    setIsModalCreateListOpen(true);
  };

  const onClickView = (id: number | string) => {
    navigate(`/${ERoute.CANDIDATES}/${id}`);
  };

  const deleteCandidate = async () => {
    await AxiosInstance.candidates
      .candidatesControllerDeleteCandidate(recordToDelete?._id!)
      .then(() => {
        toast.success('Candidate Removed successfully');
        toast.info("All Candidate's placements has been removed successfully");
        getCandidates();
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });

    setRecordToDelete(undefined);
  };

  const getPayrolls = async () => {
    AxiosInstance.payrolls
      .payrollsControllerFindAll()
      .then((response) => {
        setPayrollsList(response.data as unknown as Payroll[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getRecruiters = async () => {
    AxiosInstance.recruiters
      .recruitersControllerGetAllRecruiters()
      .then((response) => {
        setRecruitersList(response.data as unknown as Recruiter[]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const getCandidates = async () => {
    await AxiosInstance.candidates
      .candidatesControllerGetAllCandidates({
        analytics: true,
      })
      .then((response: any) => {
        const result = response.data as unknown as ExtendedCandidate[];
        setCandidates(result);
        const options = new Set(result.map((el) => el.department ?? ''));
        setDesignationOptions([...(options as any)]);
      })
      .catch((error: AxiosInstanceErrorResponse) => {
        toast.error(error.message);
      });
  };

  const paginatedData = useMemo(() => {
    return getItemsOfPage(candidatesList, currentPage, numberOfItems);
  }, [candidatesList, currentPage]);

  useEffect(() => {
    const filteredData = filterCandidates(candidates, filter);
    const sortedData = sortCandidates(filteredData, sort);
    setCandidatesList(sortedData as ExtendedCandidate[]);
    setCurrentPage(1);
    setTotalPages(getNumberOfPages(sortedData.length, numberOfItems));
  }, [candidates, filter, sort]);

  useEffect(() => {
    getCandidates();
    getPayrolls();
    getRecruiters();
  }, []);

  return (
    <>
      <TabFilter
        filters={[
          {
            title: 'Candidates',
            url: ERoute.CANDIDATES,
          },
          {
            title: 'Lists',
            url: ERoute.SHARED_CANDIDATES,
          },
        ]}
      />
      <CardContainer>
        <HeaderPageContainer>
          <div className="left-container">
            <TitlePage>Candidates</TitlePage>
            <SubTitlePage>Manage your Candidate</SubTitlePage>
          </div>
          <div className="right-container">
            <Button type="default" disabled>
              <DownloadIcon /> Download
            </Button>
            <Button type="primary" onClick={() => onClickCreate()}>
              <PlusIcon /> Add new
            </Button>
          </div>
        </HeaderPageContainer>
        <div className="filter-container">
          <SearchInput
            placeholder="Search candidate"
            onChange={(e) =>
              setFilter((prev) => ({ ...prev, search: e.target.value ?? '' }))
            }
          />
          <MultipleSelect
            placeholder="All Regions"
            options={regionsWithCounties.map((el) => ({
              label: el.region,
              value: el.region,
            }))}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                regions: values ?? [],
              }));
            }}
          />
          <MultipleSelect
            placeholder="All Counties"
            options={getCountiesOfSelectedRegions(filter.regions ?? []).map(
              (el) => ({ label: el, value: el }),
            )}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                counties: values ?? [],
              }));
            }}
            disabled={!filter.regions.length && !filter.counties.length}
          />
          <MultipleSelect
            placeholder="All Specialties"
            options={designationOptions.map((el) => ({ label: el, value: el }))}
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({
                ...prev,
                departments: values ?? [],
              }));
            }}
          />
          <MultipleSelect
            placeholder="All Levels"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, levels: values ?? [] }));
            }}
            options={jobLevelWithGrades.map((el) => ({
              label: el.level,
              value: el.level,
            }))}
          />
          <MultipleSelect
            placeholder="All Grades"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, grades: values ?? [] }));
            }}
            options={getGradesOfSelectedLevel(filter.levels).map((el) => ({
              label: el,
              value: el,
            }))}
            disabled={!filter.levels.length && !filter.grades.length}
          />
          <MultipleSelect
            placeholder="All Completions"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, completions: values ?? [] }));
            }}
            options={completionOptions}
          />
          <MultipleSelect
            placeholder="All Status"
            onChange={(e) => {
              const values = e as string[];
              setFilter((prev) => ({ ...prev, status: values ?? [] }));
            }}
            options={complianceStatusOptions}
          />
        </div>
        <div className="select-checkbox-action-container">
          <div className="number-selected-items">
            <b>{selectedCandidatesIds.length}</b> Candidates Selected
          </div>
          <div className="checkbox-action-buttons-container">
            <Button type="success" onClick={() => onClickCreateList()}>
              <PlusIcon /> Create List
            </Button>
          </div>
        </div>
        <div className="data-table-container">
          <Table>
            <thead>
              <tr>
                <th className="checkbox-table">
                  <Checkbox
                    checked={
                      !!selectedCandidatesIds.length &&
                      selectedCandidatesIds.length === candidatesList.length
                    }
                    indeterminate={
                      !!selectedCandidatesIds.length &&
                      selectedCandidatesIds.length < candidatesList.length
                    }
                    onChange={(e) => onClickCheckboxAll(e.target.checked)}
                  />
                </th>
                <th onClick={() => onSelectSort('firstName', setSort)}>
                  <div>
                    <label>Name </label>
                    <SortIcon
                      value={sort.key === 'firstName' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('designation', setSort)}>
                  <div>
                    <label>Designation</label>
                    <SortIcon
                      value={sort.key === 'designation' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th
                  onClick={() => onSelectSort('completionPercentage', setSort)}
                >
                  <div>
                    <label>Completion</label>
                    <SortIcon
                      value={
                        sort.key === 'completionPercentage' ? sort.value : ''
                      }
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('grade', setSort)}>
                  <div>
                    <label>NHS Pay Grade</label>
                    <SortIcon value={sort.key === 'grade' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('recruiter', setSort)}>
                  <div>
                    <label>Line Manager</label>
                    <SortIcon
                      value={sort.key === 'recruiter' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th onClick={() => onSelectSort('county', setSort)}>
                  <div>
                    <label>County</label>
                    <SortIcon value={sort.key === 'county' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('region', setSort)}>
                  <div>
                    <label>Region</label>
                    <SortIcon value={sort.key === 'region' ? sort.value : ''} />
                  </div>
                </th>
                <th onClick={() => onSelectSort('complianceStatus', setSort)}>
                  <div>
                    <label>Compliance Status</label>
                    <SortIcon
                      value={sort.key === 'complianceStatus' ? sort.value : ''}
                    />
                  </div>
                </th>
                <th>
                  <div></div>
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 ? (
                <>
                  {paginatedData.map((candidate) => {
                    const isCandidateBelongsToRecruiter =
                      candidate?.recruiter?._id === user?._id ||
                      user?.role === 'admin';
                    return (
                      <tr key={candidate._id}>
                        <td className="checkbox-table">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              onClickCheckbox(candidate._id, e.target.checked)
                            }
                            checked={selectedCandidatesIds.some(
                              (el) => el === candidate._id,
                            )}
                          />
                        </td>
                        <td>
                          <div className="name-item">
                            <UserIcon
                              firstName={candidate.firstName}
                              lastName={candidate.lastName}
                            />
                            <div>
                              <div>
                                {candidate.firstName} {candidate.lastName}
                              </div>
                              <div className="email">{candidate.email}</div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="name-item">
                            <div>
                              <div> {candidate?.designation}</div>
                              <div className="email">
                                {candidate.department}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="completion">
                            <ProgressBar
                              value={candidate.completionPercentage}
                            />
                            {candidate.completionPercentage}%
                          </div>
                        </td>
                        <td>{candidate?.grade}</td>
                        <td>
                          {candidate?.recruiter?.firstName}{' '}
                          {candidate?.recruiter?.lastName}
                        </td>
                        <td>{candidate.address?.county ?? '-'} </td>
                        <td>{candidate.address?.region ?? '-'} </td>
                        <td>
                          <ComplianceStatus
                            value={candidate.complianceStatus}
                          />
                        </td>
                        <td>
                          <div className="action-item">
                            <div
                              className="view-icon"
                              onClick={() => onClickView(candidate._id)}
                            >
                              <EyeIcon />
                            </div>

                            {isCRMApp && (
                              <div
                                className={`edit-icon ${
                                  !isCandidateBelongsToRecruiter && 'disabled'
                                }`}
                                onClick={() =>
                                  isCandidateBelongsToRecruiter &&
                                  onClickEdit(candidate)
                                }
                              >
                                <EditIcon />
                              </div>
                            )}

                            <div
                              className={`delete-icon ${
                                !isCandidateBelongsToRecruiter && 'disabled'
                              }`}
                              onClick={() =>
                                isCandidateBelongsToRecruiter &&
                                setRecordToDelete(candidate)
                              }
                            >
                              <DeleteIcon />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={100} className="text-center">
                    No item found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {paginatedData.length > 0 && (
          <div className="pagination-container">
            <div className="navigation-container">
              <div
                className={`left-arrow ${currentPage === 1 && 'disabled'}`}
                onClick={() => navigatePagination(currentPage - 1)}
              >
                <LeftArrowIcon />
              </div>
              <div className="pages-list">
                {Array.from(
                  { length: totalPages },
                  (_, index) => index + 1,
                ).map((el) => (
                  <div
                    className={`page-number ${el === currentPage && 'active'}`}
                    onClick={() => navigatePagination(el)}
                  >
                    {el}
                  </div>
                ))}
              </div>
              <div
                className={`right-arrow ${
                  currentPage === totalPages && 'disabled'
                }`}
                onClick={() => navigatePagination(currentPage + 1)}
              >
                <RightArrowIcon />
              </div>
            </div>
            <div className="information-container">
              Showing {currentPage} to {totalPages} of {candidatesList.length}{' '}
              entries
            </div>
          </div>
        )}
      </CardContainer>
      <SideModal
        isOpen={isModalAddCandidateOpen}
        setIsOpen={setIsModalAddCandidateOpen}
        title={'New Candidate'}
      >
        <NewCandidateForm
          onCancel={() => setIsModalAddCandidateOpen(false)}
          onSuccess={() => {
            getCandidates();
            setIsModalAddCandidateOpen(false);
          }}
          payrollsList={payrollsList}
          recruitersList={recruitersList}
        />
      </SideModal>
      <SideModal
        isOpen={!!isModalUpdateCandidateOpen}
        setIsOpen={() => setIsModalUpdateCandidateOpen(undefined)}
        title={'Update Candidate'}
      >
        <UpdateCandidateForm
          onCancel={() => setIsModalUpdateCandidateOpen(undefined)}
          onSuccess={() => {
            getCandidates();
            setIsModalUpdateCandidateOpen(undefined);
          }}
          payrollsList={payrollsList}
          recruitersList={recruitersList}
          selectedCandidate={isModalUpdateCandidateOpen!}
        />
      </SideModal>
      <SideModal
        isOpen={isModalCreateListOpen}
        setIsOpen={setIsModalCreateListOpen}
        title={'Create New List'}
      >
        <NewSharedCandidatesList
          onCancel={() => setIsModalCreateListOpen(false)}
          onSuccess={() => {
            getCandidates();
            setIsModalCreateListOpen(false);
          }}
          candidates={candidates}
          recruiters={recruitersList}
          selectedCandidatesIds={selectedCandidatesIds}
        />
      </SideModal>
      <ConfirmModal
        isOpen={recordToDelete !== undefined}
        title="Delete Candidate"
        onNegativeBtnClick={() => setRecordToDelete(undefined)}
        onPositiveBtnClick={() => deleteCandidate()}
      >
        Do you want to delete{' '}
        <b>
          {recordToDelete?.firstName} {recordToDelete?.lastName}
        </b>
        <div
          style={{
            fontSize: '15px',
            color: '#E6BB20',
          }}
        >
          <span>Note: All Candidate's placements will be removed.</span>
        </div>
      </ConfirmModal>
    </>
  );
};
